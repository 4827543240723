import { Container } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
    change_accusation_dates,
    change_selected_date,
    get_dates,
} from "../../../actions/task";
import { useTranslation } from "react-i18next";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { isMobile } from "react-device-detect";
import {
    setClickEventFail,
    setClickEventSucess,
} from "../../../actions/map";
import spinner from "../../../assets/images/Spinner-green.svg"
import Tippy, { tippy } from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import DateTooltip from "./DateTooltip";
import ReactDOMServer from 'react-dom/server';



const SidebarBottom = () => {
    const [accusationDates, setaccusationDates] = useState(null);
    const [selectedIndex, setselectedIndex] = useState(0);
    const [sidebarRef, setsidebarRef] = useState(null);

    const [selectedDate, setselectedDate] = useState(null);

    const dates = useSelector((state) => state.mapReducer.AccusationDates);
    const selected_date_state = useSelector(
        (state) => state.mapReducer.SelectedDate
    );
    const taskIdDetailsEdit = useSelector(
        (state) => state.mapReducer.taskIdDetails
    );

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const options = {
        0: {
            items: 2,
        },
        330: {
            items: 3,
        },
        417: {
            items: 4,
        },
        600: {
            items: 4,
        },
        700: {
            items: 4,
        },
        1000: {
            items: 4,
        },
        1200: {
            items: 4,
        },
    };



    useEffect(() => {
        if (!sidebarRef) return;

        if (isMobile) {
            sidebarRef.addEventListener("touchstart", disableMapInteractions); // On touchstart
            sidebarRef.addEventListener("touchmove", disableMapInteractions); // On touchmove
        }
        sidebarRef.addEventListener("mouseenter", disableMapInteractions);
        sidebarRef.addEventListener("mouseleave", enableMapInteractions);
        return () => {
            sidebarRef.removeEventListener("mouseenter", disableMapInteractions);
            sidebarRef.removeEventListener("mouseleave", enableMapInteractions);
            sidebarRef.removeEventListener("touchstart", disableMapInteractions);
            sidebarRef.removeEventListener("touchend", enableMapInteractions);
            sidebarRef.removeEventListener("touchmove", enableMapInteractions);
        };
    }, [sidebarRef]);
    const disableMapInteractions = () => {
        dispatch(setClickEventFail());
    };
    const enableMapInteractions = () => {
        dispatch(setClickEventSucess());
    };
    useEffect(() => {
        if (!taskIdDetailsEdit) return;
        dispatch(get_dates(taskIdDetailsEdit)).then((res_date) => {
            dispatch(change_accusation_dates(res_date.data.data["dates"]));
        });
    }, [taskIdDetailsEdit]);
    useEffect(() => {
        if (!selected_date_state) return
        setselectedDate(selected_date_state)
    }, [selected_date_state]);
    useEffect(() => {
        setaccusationDates(dates);
    }, [dates]);

    const changeSelectedDate = (date, provider, index) => {
        setselectedIndex(index);
        dispatch(change_selected_date(date, provider));
    };
    const itemShowTooltip = (date, provider, image, cloud_percentage, index) => {
        const tooltipContent = ReactDOMServer.renderToString(
            <DateTooltip date={date} provider={provider} image={image} cloud_percentage={cloud_percentage} index={index} />
        ); tippy('#item-' + index, {
            content: tooltipContent,
            // arrow: false,
            allowHTML: true,
            theme: 'light',
            animation: 'scale',
            delay: [500, 0],
            interactive: true,
            appendTo: document.body,
            onHidden: (instance) => {
                instance.destroy()
            }
        })

    }
    return (
        <div className="sidebar-bottom" ref={setsidebarRef}>
            <Container
                maxWidth="md"
                className="tab-content container-sidebar"
            >
                <Row className="pt-2 pb-2" >

                    {accusationDates && selectedDate ? (
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <OwlCarousel
                                className="owl-theme"
                                loop
                                margin={10}
                                nav
                                items={3}
                                mouseDrag={true}
                                dots={false}
                                responsive={options}
                                startPosition={selectedIndex}
                                rewind={false}
                            >
                                {accusationDates.map((item, index) => (
                                    <div
                                        className={
                                            selectedDate.date === item.date
                                                ? "item owl-item-container active"
                                                : "item owl-item-container"
                                        }
                                        key={index}
                                        id={"item-" + index}
                                        onClick={() => changeSelectedDate(item.date, item.provider, index)}
                                        onMouseOver={() => itemShowTooltip(item.date, item.provider, item.image, item.cloud_percentage, index)}
                                    >
                                        <div className="item-inner-content">
                                            <img
                                                src={`${process.env.REACT_APP_API_URL}${item.image}`}
                                                alt={item.date}
                                                className="owl-item-img"
                                            />
                                            <div className="owl-item-date">{item.date} </div>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </Col>
                    ) : (
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <Row>
                                <div className="col-12 text-center justify-content-center d-flex">
                                    <img src={spinner} alt="loading" style={{ height: "50px" }} />
                                </div>
                                <div className="col-12 text-center">
                                    <span style={{ fontSize: "15px", fontWeight: "600" }}>
                                        {t("Loading ...")}
                                    </span>
                                </div>
                            </Row>
                        </Col>
                    )}

                </Row>
            </Container>
        </div>
    )
}

export default SidebarBottom
