function hexToRgb(hex) {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r, g, b];
}

function discreteCmapFromHex(hexColors) {
  // Convert the list of hex colors to a list of RGB colors
  const rgbColors = hexColors.map((hex) => hexToRgb(hex));

  // Create a dictionary mapping each integer from 0 to 254 to an RGB color
  const colorMap = {};
  const length = rgbColors.length;

  for (let x = 0; x < 255; x++) {
    const index = Math.floor((x / 256.0) * length);
    colorMap[x] = rgbColors[index];
  }

  return colorMap;
}

export const colormap = {
  rdylgn: [
    [165, 0, 38, 255],
    [166, 1, 38, 255],
    [168, 3, 38, 255],
    [170, 5, 38, 255],
    [172, 7, 38, 255],
    [174, 9, 38, 255],
    [176, 11, 38, 255],
    [178, 13, 38, 255],
    [180, 15, 38, 255],
    [182, 16, 38, 255],
    [184, 18, 38, 255],
    [186, 20, 38, 255],
    [188, 22, 38, 255],
    [190, 24, 38, 255],
    [192, 26, 38, 255],
    [194, 28, 38, 255],
    [196, 30, 38, 255],
    [198, 32, 38, 255],
    [200, 33, 38, 255],
    [202, 35, 38, 255],
    [204, 37, 38, 255],
    [206, 39, 38, 255],
    [208, 41, 38, 255],
    [210, 43, 38, 255],
    [212, 45, 38, 255],
    [214, 47, 38, 255],
    [215, 49, 39, 255],
    [216, 51, 40, 255],
    [217, 53, 41, 255],
    [218, 56, 42, 255],
    [220, 58, 43, 255],
    [221, 61, 45, 255],
    [222, 63, 46, 255],
    [223, 65, 47, 255],
    [224, 68, 48, 255],
    [225, 70, 49, 255],
    [226, 73, 50, 255],
    [228, 75, 51, 255],
    [229, 77, 52, 255],
    [230, 80, 53, 255],
    [231, 82, 54, 255],
    [232, 85, 56, 255],
    [233, 87, 57, 255],
    [234, 89, 58, 255],
    [236, 92, 59, 255],
    [237, 94, 60, 255],
    [238, 97, 61, 255],
    [239, 99, 62, 255],
    [240, 101, 63, 255],
    [241, 104, 64, 255],
    [242, 106, 65, 255],
    [244, 109, 67, 255],
    [244, 111, 68, 255],
    [244, 114, 69, 255],
    [245, 116, 70, 255],
    [245, 119, 71, 255],
    [245, 121, 72, 255],
    [246, 124, 74, 255],
    [246, 126, 75, 255],
    [246, 129, 76, 255],
    [247, 131, 77, 255],
    [247, 134, 78, 255],
    [247, 137, 79, 255],
    [248, 139, 81, 255],
    [248, 142, 82, 255],
    [248, 144, 83, 255],
    [249, 147, 84, 255],
    [249, 149, 85, 255],
    [250, 152, 86, 255],
    [250, 154, 88, 255],
    [250, 157, 89, 255],
    [251, 159, 90, 255],
    [251, 162, 91, 255],
    [251, 165, 92, 255],
    [252, 167, 94, 255],
    [252, 170, 95, 255],
    [252, 172, 96, 255],
    [253, 174, 97, 255],
    [253, 176, 99, 255],
    [253, 178, 101, 255],
    [253, 180, 102, 255],
    [253, 182, 104, 255],
    [253, 184, 106, 255],
    [253, 186, 107, 255],
    [253, 188, 109, 255],
    [253, 190, 110, 255],
    [253, 192, 112, 255],
    [253, 194, 114, 255],
    [253, 196, 115, 255],
    [253, 198, 117, 255],
    [253, 200, 119, 255],
    [253, 202, 120, 255],
    [253, 204, 122, 255],
    [253, 206, 124, 255],
    [253, 208, 125, 255],
    [253, 210, 127, 255],
    [253, 212, 129, 255],
    [253, 214, 130, 255],
    [253, 216, 132, 255],
    [253, 218, 134, 255],
    [253, 220, 135, 255],
    [253, 222, 137, 255],
    [254, 224, 139, 255],
    [254, 225, 141, 255],
    [254, 226, 143, 255],
    [254, 227, 145, 255],
    [254, 228, 147, 255],
    [254, 230, 149, 255],
    [254, 231, 151, 255],
    [254, 232, 153, 255],
    [254, 233, 155, 255],
    [254, 234, 157, 255],
    [254, 236, 159, 255],
    [254, 237, 161, 255],
    [254, 238, 163, 255],
    [254, 239, 165, 255],
    [254, 241, 167, 255],
    [254, 242, 169, 255],
    [254, 243, 171, 255],
    [254, 244, 173, 255],
    [254, 245, 175, 255],
    [254, 247, 177, 255],
    [254, 248, 179, 255],
    [254, 249, 181, 255],
    [254, 250, 183, 255],
    [254, 251, 185, 255],
    [254, 253, 187, 255],
    [254, 254, 189, 255],
    [254, 254, 189, 255],
    [252, 254, 187, 255],
    [251, 253, 185, 255],
    [249, 252, 183, 255],
    [248, 252, 181, 255],
    [246, 251, 179, 255],
    [245, 250, 177, 255],
    [243, 250, 175, 255],
    [242, 249, 173, 255],
    [240, 249, 171, 255],
    [239, 248, 169, 255],
    [237, 247, 167, 255],
    [236, 247, 165, 255],
    [234, 246, 163, 255],
    [233, 245, 161, 255],
    [231, 245, 159, 255],
    [230, 244, 157, 255],
    [228, 244, 155, 255],
    [227, 243, 153, 255],
    [225, 242, 151, 255],
    [224, 242, 149, 255],
    [222, 241, 147, 255],
    [221, 240, 145, 255],
    [219, 240, 143, 255],
    [218, 239, 141, 255],
    [217, 239, 139, 255],
    [215, 238, 137, 255],
    [213, 237, 136, 255],
    [211, 236, 135, 255],
    [209, 235, 133, 255],
    [207, 234, 132, 255],
    [205, 233, 131, 255],
    [203, 232, 129, 255],
    [201, 232, 128, 255],
    [199, 231, 127, 255],
    [197, 230, 126, 255],
    [195, 229, 124, 255],
    [193, 228, 123, 255],
    [191, 227, 122, 255],
    [189, 226, 120, 255],
    [187, 226, 119, 255],
    [185, 225, 118, 255],
    [183, 224, 117, 255],
    [181, 223, 115, 255],
    [179, 222, 114, 255],
    [177, 221, 113, 255],
    [175, 220, 111, 255],
    [173, 220, 110, 255],
    [171, 219, 109, 255],
    [169, 218, 107, 255],
    [167, 217, 106, 255],
    [164, 216, 105, 255],
    [162, 215, 105, 255],
    [159, 214, 105, 255],
    [157, 213, 105, 255],
    [154, 212, 104, 255],
    [152, 210, 104, 255],
    [149, 209, 104, 255],
    [147, 208, 103, 255],
    [144, 207, 103, 255],
    [142, 206, 103, 255],
    [139, 205, 103, 255],
    [137, 204, 102, 255],
    [134, 203, 102, 255],
    [132, 202, 102, 255],
    [129, 201, 102, 255],
    [127, 199, 101, 255],
    [124, 198, 101, 255],
    [122, 197, 101, 255],
    [119, 196, 100, 255],
    [117, 195, 100, 255],
    [114, 194, 100, 255],
    [112, 193, 100, 255],
    [109, 192, 99, 255],
    [107, 191, 99, 255],
    [104, 190, 99, 255],
    [102, 189, 99, 255],
    [99, 187, 98, 255],
    [96, 186, 97, 255],
    [93, 184, 96, 255],
    [90, 183, 96, 255],
    [87, 181, 95, 255],
    [84, 180, 94, 255],
    [81, 178, 93, 255],
    [78, 177, 93, 255],
    [75, 175, 92, 255],
    [72, 174, 91, 255],
    [69, 173, 90, 255],
    [66, 171, 90, 255],
    [63, 170, 89, 255],
    [60, 168, 88, 255],
    [57, 167, 87, 255],
    [54, 165, 87, 255],
    [51, 164, 86, 255],
    [48, 162, 85, 255],
    [45, 161, 84, 255],
    [42, 159, 84, 255],
    [39, 158, 83, 255],
    [36, 157, 82, 255],
    [33, 155, 81, 255],
    [30, 154, 81, 255],
    [27, 152, 80, 255],
    [25, 151, 79, 255],
    [24, 149, 78, 255],
    [23, 147, 77, 255],
    [22, 145, 76, 255],
    [21, 143, 75, 255],
    [20, 141, 74, 255],
    [19, 139, 73, 255],
    [18, 137, 72, 255],
    [17, 136, 71, 255],
    [16, 134, 70, 255],
    [15, 132, 69, 255],
    [14, 130, 68, 255],
    [13, 128, 67, 255],
    [12, 126, 66, 255],
    [11, 124, 65, 255],
    [10, 122, 64, 255],
    [9, 120, 63, 255],
    [8, 119, 62, 255],
    [7, 117, 61, 255],
    [6, 115, 60, 255],
    [5, 113, 59, 255],
    [4, 111, 58, 255],
    [3, 109, 57, 255],
    [2, 107, 56, 255],
    [1, 105, 55, 255],
    [0, 104, 55, 255],
  ],
};
