import React, { useState } from "react";
import { Dispatch } from "react";
import BasicDatePicker from "../../../utils/BasicDatePicker";
import MonthRangePicker from "../../../utils/MonthRangePicker";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getIndexArray,
  getStartEndDate,
  addForecastParams,
  getPattern,
} from "../../../actions/graph";

import {
  getTaskWeather,
  Detail_Task_Map,
} from "../../../actions/wether"

import dayjs from "dayjs";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Form from "react-bootstrap/Form";
import { Alert, Box, Button, Container, Slider, Typography } from "@mui/material";
import { LOADING_BEGIN } from "../../../utils/types";
import SaveIcon from "@mui/icons-material/Save";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Graph from "./graph";
import { getTaskHistory } from "../../../actions/wether.js";

import { showSnackbar } from "../../../actions/snackbar";
import { useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  handleFilterData,
  handleFilterDataByCloudPercentage,
  findMinMaxValues,
} from "../../../utils/graphDataFilter.js";
import GraphYear from "./graphYear.js";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { getForecastPredict, getIndexForecast } from "../../../actions/graph.js";
import { get_task_notifications, getTaskPatternAlerts, getTaskNdviForecastAlerts} from "../../../actions/notification.js";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Chip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { getForecastParams } from "../../../actions/graph.js";
import { ForecastTrain } from "../../../actions/graph.js"
import { ForecastPredict } from "./../../../actions/graph";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
const GraphPage = () => {
  const dispatch = useDispatch();
  const start_end_dates = useSelector(
    (state) => state.graphReducer.graph_dates
  );

  const taskId = useSelector((state) => state.mapReducer.taskIdDetails);
  const user = useSelector((state) => state.auth.user);
  
  const [data, setdata] = useState([]);
  const [dataPerYear, setdataPerYear] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingForecast, setloadingForecast] = useState(false);
  const { t } = useTranslation();
  const [indexText, setindexText] = useState("Vegetation Index");
  const [currentIndex, setCurrentIndex] = useState("Vegetation Index");
  const data_graph = useSelector((state) => state.graphReducer.graph_data);
  const today = dayjs();
  const [attemptCount, setAttemptCount] = useState(0);
  const [graphDataReady, setgraphDataReady] = useState(false);
  const [dataPredict, setdataPredict] = useState([]);
  const [IndexForecast, setIndexForecast] = useState([]);

  
  const [dataPredictIndex, setdataPredictIndex] = useState([]);
  const [notifications, setnotifications] = useState([]);
  const [patternAlertsDates, setPatternAlertsDates] = useState({"ordinaryAlerts" : [], "severeAlerts" : []});
  const [ndviForecastAlertsDates, setNdviForecastAlertsDates] = useState({"ordinaryAlerts" : [], "severeAlerts" : []});
  
  const [years, setyears] = useState([]);
  const [yearsRemoveList, setyearsRemoveList] = useState([]);
  const [bestYear, setbestYear] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  const [dailyRain, setDailyRain] = useState([]);
  const [rainMax, setRainMax] = useState(0);
  const [rainMin, setRainMin] = useState(0);
  const [yearGraphMonth, setYearGraphMonth] = useState([0, 11]);

  const [patternData, setpatternData] = useState([]);
  const [formData, setFormData] = useState({
    StartDate: today.subtract(1, "year"),
    EndDate: today,
    index: "ndvi",
  });
  const marks = [
    {
      value: 10,
      label: "10 %",
    },
    {
      value: 20,
      label: "20 %",
    },
    {
      value: 30,
      label: "30 %",
    },
    {
      value: 40,
      label: "40 %",
    },
  ]


  const getRainfallData = () => {
    let startdate_str = formData.StartDate.format('YYYY-MM-DD')
    let enddate_str = formData.EndDate.format('YYYY-MM-DD')
    // if (formData.StartDate > formData.EndDate) {
    //   dispatch(showSnackbar("error", undefined, "end date must be after start date ", "top", "right"))
    //   return;
    // }

    // if (endDate > today.toISOString().split('T')[0]) {
    //   dispatch(showSnackbar("error", undefined, "end date must be before today's date ", "top", "right"))
    //   return;
    // }
    dispatch(getTaskHistory(lat, lng, startdate_str, enddate_str)).then((response) => {

      let rain_data = []
      let rain_max = Math.max(...response.daily.rain_sum);
      let rain_min = Math.min(...response.daily.rain_sum);

      for (let i = 0; i < response.daily.rain_sum.length; i++) {
        const obj = { "date": response.daily.time[i], "value": response.daily.rain_sum[i] }
        rain_data.push(obj)
      }

      setDailyRain(rain_data)
      setRainMax(rain_max)
      setRainMin(rain_min)
      // .daily.rain_sum);
    })
  };
  // Function to extract unique years from the data
  function extractUniqueYears(data) {
    const yearsSet = new Set();
    data.forEach((item) => {
      const year = item.date.split("-")[0];
      yearsSet.add(year);
    });
    return Array.from(yearsSet);
  }
  // Function to extract data for each year
  function extractDataForYear(data, year) {
    return data.filter((item) => item.date.startsWith(year));
  }

  useEffect(() => {
    if (!taskId) return
    dispatch(Detail_Task_Map(taskId)).then((res) => {
      setLat(res.data.lat);
      setLng(res.data.lng);
    })
  }, [taskId])

  useEffect(() => {
    if (!taskId) return;
    dispatch(getForecastPredict(taskId)).then((response) => {
      if (response.status === 200) {
        setdataPredict(response.data.results);
      } else {
        setdataPredict([]);
      }
    });

    dispatch(getIndexForecast(taskId)).then((response) => {
      if (response.status === 200) {
        setIndexForecast(response.data.results);
      } else {
        setIndexForecast([]);
      }
    });

    dispatch(getPattern(taskId)).then((res) => {
      if (res.status === 200 && res.data) {
        try{
          
          let originalYearFirst = res.data.data[0].date.split('-')[0]
          let originalYearSecond = res.data.data[res.data.data.length -1].date.split('-')[0]
          let currentYear = new Date().getFullYear()
          setpatternData(res.data);
       
          if(originalYearFirst === originalYearSecond){
            let altered_year_data = res.data.data.map(obj => {
              console.log("obj", obj)
              return {
                "date" : obj.date.replace(originalYearFirst, currentYear),
                "value" : obj.value,
                "NDVI_rolling_mean" : obj.NDVI_rolling_mean,
                "cloud_percentage" : obj.cloud_percentage
                
              }
            })
            setpatternData(altered_year_data);
  
          }
          else{
            let altered_year_data = res.data.data.map(obj => {
              console.log("obj", obj)

              return {
                "date" : obj.date.replace(originalYearSecond, currentYear+1).replace(originalYearFirst, currentYear),
                "value" : obj.value,
                "NDVI_rolling_mean" : obj.NDVI_rolling_mean,
                "cloud_percentage" : obj.cloud_percentage
                
              }
            })

            setpatternData(altered_year_data);
          }


        }
        catch(err){
          console.log("err", err)
        }

      }
    });


  }, [taskId]);

  useEffect(() => {
    if (!lat) return

    getRainfallData()

  }, [lat])

  useEffect(() => {
    setFormData({
      ...formData,
      StartDate: today.subtract(1, "year"),
      EndDate: today,
      cloud_percentage: 40,
    });
  }, []);
  const { StartDate, EndDate, index, cloud_percentage } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onChangeIndex = (e) => {
    setFormData({ ...formData, index: e.target.value });
    switch (e.target.value) {
      case "ndvi":
        setindexText("Vegetation Index");
        break;
      case "ndre":
        setindexText("Nitrogen Index");
        break;
      case "ndwi":
        setindexText("Irrigation index");
        break;
      case "gci":
        setindexText("Plant Health Index");
        break;
      case "psri":
        setindexText("Plant Senescence Index");
        break;
      default:
        setindexText("Vegetation Index");
    }
  };
  const onChangeReferenceYear = (e) => {
    setbestYear(e.target.value);
  };
  const onChangeBadYears = (e) => {
    setyearsRemoveList(e.target.value);
  };
  const onChangeSlider = (e, value) => {
    setFormData({ ...formData, cloud_percentage: value });
  };
  function valuetext(value) {
    return `${value} %`;
  }
  useEffect(() => {
    if (!taskId) return;
    dispatch(get_task_notifications(taskId)).then((res) => {
      if (res.status === 200) {
        setnotifications(res.data.data);

      } else {
        setnotifications([]);
      }
    });

    dispatch(getTaskPatternAlerts(user.id, taskId, 0)).then((res) => {
      if (res.status === 200) {
        let alertsDates = {"ordinaryAlerts" : [], "severeAlerts" : []}
        res.data.alerts.map(alert => {
          if(alert.difference >= 0.2 || alert.difference <= -0.2){
            alertsDates.severeAlerts.push(alert.time.split('T')[0])
          }else{
            alertsDates.ordinaryAlerts.push(alert.time.split('T')[0])
          }
        })

        setPatternAlertsDates(alertsDates);
      } else {
        setPatternAlertsDates({"ordinaryAlerts" : [], "severeAlerts" : []});
      }
    });

    dispatch(getTaskNdviForecastAlerts(user.id, taskId, 0)).then((res) => {
      if (res.status === 200) {
        let alertsDates = {"ordinaryAlerts" : [], "severeAlerts" : []}
        res.data.alerts.map(alert => {
          if(alert.difference >= 0.2 || alert.difference <= -0.2){
            alertsDates.severeAlerts.push(alert.time.split('T')[0])
          }else{
            alertsDates.ordinaryAlerts.push(alert.time.split('T')[0])
          }
        })
        setNdviForecastAlertsDates(alertsDates);
      } else {
        setNdviForecastAlertsDates({"ordinaryAlerts" : [], "severeAlerts" : []});
      }
    });

    dispatch(getForecastParams(taskId)).then((res) => {
      if (res.status === 200) {
        setbestYear(res.data.data.bestYear);
        setyearsRemoveList(res.data.data.BadYears);
      }
    });
  }, [taskId]);
  useEffect(() => {
    if (!taskId) return;
    if (data_graph) {
      setLoading(false);
      return;
    }
    let intervalId;
    const checkGraphData = () => {
      if (!graphDataReady) {
        if (attemptCount < 36) {
          dispatch(getIndexArray(taskId)).then((res) => {
            if (res.status === 200) {
              setgraphDataReady(true);
              setLoading(false);
              clearInterval(intervalId);
            }
          });
          setAttemptCount(attemptCount + 1);
        } else {
          // Stop the interval after reaching maxAttempts wichh is 3 min
          clearInterval(intervalId);
        }
      } else {
        clearInterval(intervalId);
        setLoading(false);
      }
    };
    intervalId = setInterval(checkGraphData, 5000);
    // Initial check
    checkGraphData();
    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [taskId, data_graph]);

  useEffect(() => {
    if (!data_graph) return;
    const ndviElement = data_graph.find((item) => item.index === "ndvi");
    let dataFiltred = [];
    let dataSmoothedFiltred = [];
    dataFiltred = handleFilterData(ndviElement.data, StartDate, EndDate);
    dataSmoothedFiltred = handleFilterData(
      ndviElement.data_smoothed,
      StartDate,
      EndDate
    );
    const { minValue, maxValue } = findMinMaxValues(
      dataFiltred,
      dataSmoothedFiltred
    );
    const arr = {
      data: dataFiltred,
      data_smoothed: dataSmoothedFiltred,
      min: minValue,
      max: maxValue,
    };
    setdata(arr);
    if (dataPredict.length !== 0) {
      const ElementPredict = dataPredict["ndvi"];
      setdataPredictIndex(ElementPredict.data_predict_fortnite);
    } else {
      setdataPredictIndex([]);
    }
    // Extract unique years
    const uniqueYears = extractUniqueYears(ndviElement.data);
    setyears(uniqueYears);
    // const dataWithCloudMask = handleFilterDataByCloudPercentage(ndviElement.data, cloud_percentage)
    const dataByYear = {};
    uniqueYears.forEach((year) => {
      dataByYear[year] = extractDataForYear(ndviElement.data, year);
    });
    // const { minValueAllYears, maxValueAllYears } = findMinMaxValues(ndviElement.data, ndviElement.data);
    const valuesArray = ndviElement.data.map((item) => parseFloat(item.value));
    // Find min and max values
    const minValueAllYears = Math.min(...valuesArray);
    const maxValueAllYears = Math.max(...valuesArray);
    const arr2 = [
      {
        data: dataByYear,
        min: minValueAllYears,
        max: maxValueAllYears,
        years: uniqueYears,
      },
    ];
    setdataPerYear(arr2);
  }, [data_graph, dataPredict]);
  const renderSelectGroup = (year) => {
    return (
      <MenuItem
        key={year}
        value={year}
        sx={{ justifyContent: "space-between" }}
      >
        {year}
        {Array.isArray(yearsRemoveList) && yearsRemoveList.includes(year) ? (
          <CheckIcon color="info" />
        ) : null}
      </MenuItem>
    );
  };

  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    // Validation: Check if StartDate, EndtDate, and index are not empty
    if (
      !StartDate ||
      !EndDate ||
      !index ||
      !StartDate.isValid() ||
      !EndDate.isValid()
    ) {
      dispatch(
        showSnackbar(
          "error",
          undefined,
          t("Please checkout all fields !"),
          "top",
          "right"
        )
      );
      setLoading(false);
    } else if (!StartDate.isBefore(EndDate)) {
      dispatch(
        showSnackbar(
          "error",
          undefined,
          t("Start date must be before end date !"),
          "top",
          "right"
        )
      );
    } else {

      const indexElement = data_graph.find((item) => item.index === index);
      // Function to handle filtering based on start and end dates
      let dataFiltred = [];
      let dataSmoothedFiltred = [];
      let dataFiltredByCloudPercentage = [];
      let dataSmoothedFiltredByCloudPercentage = [];
      dataFiltred = handleFilterData(indexElement.data, StartDate, EndDate);
      dataSmoothedFiltred = handleFilterData(
        indexElement.data_smoothed,
        StartDate,
        EndDate
      );
      dataFiltredByCloudPercentage = handleFilterDataByCloudPercentage(
        dataFiltred,
        cloud_percentage
      );
      dataSmoothedFiltredByCloudPercentage = handleFilterDataByCloudPercentage(
        dataSmoothedFiltred,
        cloud_percentage
      );
      const { minValue, maxValue } = findMinMaxValues(
        dataFiltred,
        dataSmoothedFiltred
      );
      const arr1 = {
        data: dataFiltredByCloudPercentage,
        data_smoothed: dataSmoothedFiltredByCloudPercentage,
        min: minValue,
        max: maxValue,
      };
      setdata(arr1);
      if (dataPredict.length > 0) {
        const ElementPredict = dataPredict[index];
        setdataPredictIndex(ElementPredict.data_predict_fortnite);
      }
      const uniqueYears = extractUniqueYears(indexElement.data);
      // Extract data for each year
      const dataWithCloudMask = handleFilterDataByCloudPercentage(
        indexElement.data,
        cloud_percentage
      );
      const dataByYear = {};
      uniqueYears.forEach((year) => {
        dataByYear[year] = extractDataForYear(dataWithCloudMask, year);
      });
      // const { minValueAllYears, maxValueAllYears } = findMinMaxValues(indexElement.data, indexElement.data);
      const valuesArray = indexElement.data.map((item) =>
        parseFloat(item.value)
      );
      // Find min and max values
      const minValueAllYears = Math.min(...valuesArray);
      const maxValueAllYears = Math.max(...valuesArray);
      const arr2 = [
        {
          data: dataByYear,
          min: minValueAllYears,
          max: maxValueAllYears,
          years: uniqueYears,
        },
      ];

      getRainfallData(StartDate, EndDate)


      setdataPerYear(arr2);
      setLoading(false);
    }


    setLoading(false);
    setCurrentIndex(indexText)
  };
  const onSubmitParams = (e) => {
    e.preventDefault();
    setloadingForecast(true);
    dispatch(addForecastParams(taskId, bestYear, yearsRemoveList)).then(
      (res) => {
        if (res.status === 200) {
          dispatch(ForecastTrain(taskId)).then((response) => {
            if (response.status === 200) {
              dispatch(ForecastPredict(taskId)).then((res2) => {
                if (res2.status === 200) {
                  dispatch(getForecastPredict(taskId)).then((response) => {
                    if (response.status === 200) {
                      setdataPredict(response.data.results);
                      dispatch(
                        showSnackbar(
                          "success",
                          undefined,
                          t(res.data.message),
                          "top",
                          "right",
                          "10000"
                        )
                      );
                      setloadingForecast(false);
                    } else {
                      setdataPredict([]);
                      dispatch(
                        showSnackbar(
                          "error",
                          "something went wrong !",
                          t(res.data.message),
                          "top",
                          "right",
                          "3000"
                        )
                      );
                      setloadingForecast(false);
                    }
                  });
                }
              });
            }
          });

          dispatch(get_task_notifications(taskId)).then((res) => {
            if (res.status === 200) {
              setnotifications(res.data.data);
            } else {

              setnotifications([]);
            }
          });
        }
        // setloadingForecast(false)
      }
    );
  };
  const applyMonthRange = (range) => {
    // console.log(range)
    // console.log(dataPerYear[0])
    setYearGraphMonth(range)
    // for (const [key, value] of Object.entries(dataPerYear[0].data)) {
    //   // console.log(`${key}: ${value}`);
    //   let startDate = new Date(`${key}-${range[0]}-01`)
    //   let endDate = new Date(`${key}-${range[1]}-30`)
    //   console.log(startDate, endDate)
    // }
    // let obj = dataPerYear[0].data
    // for (const key in obj) {
    //   console.log(obj.key)
    //   // let startDate = new Date(`${element}-${range[0]}-01`)
    //   // let endDate = new Date(`${element}-${range[1]}-30`)
    //   // console.log(startDate, endDate)
    // }
  }

  return (
    <Container maxWidth="xl">
      <Row className="pt-4 pb-4" >
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography variant="h6">{t("Graph Data Filters")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Form onSubmit={(e) => onSubmit(e)}>
              <div className="row">
                <div className="col-md-4">
                  {start_end_dates ? (
                    <BasicDatePicker
                      disabled={loading}
                      className="graph_date_input"
                      label={t("start date")}
                      value={StartDate}
                      name={"StartDate"}
                      minDate={dayjs("2017-04-01")}
                      maxDate={EndDate}
                      onChange={(e) => onChange(e)}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4">
                  {start_end_dates ? (
                    <BasicDatePicker
                      disabled={loading}
                      className="graph_date_input"
                      label={t("end date")}
                      value={EndDate}
                      name={"EndDate"}
                      onChange={(e) => onChange(e)}
                      minDate={dayjs("2017-04-01")}
                      maxDate={EndDate}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4">
                  <FormControl
                    sx={{ mt: 1, mb: 1 }}
                    size="small"
                    className="m-select col-sm-12 "
                  >
                    <InputLabel id="demo-select-index-label">
                      {t("Index")}
                    </InputLabel>
                    <Select
                      disabled={loading}
                      labelId="demo-select-index-label"
                      id="demo-select-index"
                      label="Index"
                      fullWidth
                      onChange={(e) => onChangeIndex(e)}
                      name="Index"
                      defaultValue={"ndvi"}
                      value={index}
                    >
                      <MenuItem value="ndvi">{t("Vegetation Index")}</MenuItem>
                      <MenuItem value="ndwi">{t("Irrigation index")}</MenuItem>
                      <MenuItem value="ndre">{t("Nitrogen Index")}</MenuItem>
                      <MenuItem value="gci">{t("Plant Health Index")}</MenuItem>
                      <MenuItem value="psri">
                        {t("Plant Senescence Index")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <Typography id="input-slider" gutterBottom>
                    Cloud percentage
                  </Typography>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-11">
                  <Slider
                    aria-label="Temperature"
                    defaultValue={40}
                    getAriaValueText={valuetext}
                    valueLabelDisplay="on"
                    marks={marks}
                    min={10}
                    max={40}
                    step={10}
                    disabled={loading}
                    name="cloud_percentage"
                    value={cloud_percentage}
                    onChange={onChangeSlider}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 d-flex justify-content-center">
                  <LoadingButton
                    size="small"
                    color="primary"
                    type="submit"
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                  >
                    <span>{t("Process")}</span>
                  </LoadingButton>
                </div>
              </div>
            </Form>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography variant="h6">{t("Forecast Data Settings")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {t(
                "Select the 'Reference Year' that exhibited optimal production levels with minimal disease and nutrient deficiencies."
              )}
              <br></br>
              {t(
                "For 'Low-Performance Years' choose years that faced challenges such as pest attacks, low production, or poor management."
              )}{" "}
              <br></br>
              {t(
                "These selections will help analyze and compare forecast data to assess trends and potential improvements."
              )}
              <Form onSubmit={(e) => onSubmitParams(e)}>
                <div className="row mt-3">
                  <div className="col-md-4">
                    <FormControl
                      sx={{ mt: 1, mb: 1 }}
                      size="small"
                      className="m-select col-sm-12 "
                      required
                    >
                      <InputLabel id="demo-select-best-year">
                        {t("Reference Year")}
                      </InputLabel>
                      <Select
                        disabled={loadingForecast}
                        labelId="demo-select-best-year"
                        id="demo-select-best-year"
                        label={t("Reference Year")}
                        fullWidth
                        required
                        onChange={(e) => onChangeReferenceYear(e)}
                        name="bestYear"
                        value={bestYear}
                      >
                        {years && years.length > 0 ? (
                          years.map((year, index) => (
                            <MenuItem
                              value={year}
                              key={index}
                              selected={bestYear === year}
                            >
                              {year}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>
                            {t("No years available")}
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-4">
                    <FormControl
                      sx={{ mt: 1, mb: 1 }}
                      size="small"
                      className="m-select col-sm-12"
                      required
                    >
                      <InputLabel id="demo-select-bad-year">
                        {t("Low-Performance Years")}
                      </InputLabel>
                      <Select
                        disabled={loadingForecast}
                        labelId="demo-select-bad-label"
                        id="demo-select-bad-year"
                        label={t("Low-Performance Years")}
                        fullWidth
                        multiple
                        required
                        onChange={(e) => onChangeBadYears(e)}
                        name="bestYear"
                        value={yearsRemoveList}
                        renderValue={(selected) => (
                          <Stack gap={1} direction="row" flexWrap="wrap">
                            {selected.map((value) => {
                              const foundYear = years.find(
                                (year) => year === value
                              );
                              console.log("foundYear", typeof foundYear);
                              console.log("value", typeof value);

                              return (
                                <Chip
                                  key={value}
                                  label={foundYear ? foundYear : ""}
                                  onDelete={() =>
                                    setyearsRemoveList(
                                      yearsRemoveList.filter(
                                        (item) => item !== value
                                      )
                                    )
                                  }
                                  deleteIcon={
                                    <CancelIcon
                                      onMouseDown={(event) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                />
                              );
                            })}
                          </Stack>
                        )}
                      >
                        {years &&
                          years.length > 0 &&
                          years.map((item) => renderSelectGroup(item))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-4 d-flex justify-content-center align-items-center">
                    <LoadingButton
                      size="small"
                      color="success"
                      type="submit"
                      loading={loadingForecast}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="contained"
                    >
                      <span>{t("Save")}</span>
                    </LoadingButton>
                  </div>
                </div>
              </Form>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <div className="row mt-5">
          <div className="col-md-12 d-flex justify-content-center">
            <Typography variant="h5" component="h2">
              Historical {currentIndex} Data
            </Typography>
          </div>
        </div>

        {indexText && data && (
          <div 
          className="col-md-12"
           style={{}}>
            <Graph
              vegetationIndex={currentIndex}
              data={data}
              datapredict={dataPredictIndex}
              indexForecast={IndexForecast}
              notifications={notifications}
              pattern={patternData}
              rainData={dailyRain}
              rain_max={rainMax}
              rain_min={rainMin}
              patternAlertsDates={patternAlertsDates}
              ndviForecastAlertsDates={ndviForecastAlertsDates}

            />
          </div>
        )}
        <div className="col-md-12 mt-5">

          <div className="col-md-12 d-flex justify-content-center">
            <Typography variant="h5" component="h2">
              Yearly Comparison of {currentIndex}
            </Typography>
          </div>
        </div>
          <div className="mt-3" style={{maxWidth:"80%", margin: "0 auto"}}>
            <MonthRangePicker handleMonthRange={applyMonthRange} />
          </div>

        {indexText && dataPerYear.length > 0 && (
          <div
            className="mt-3 mb-10"
            style={{ marginBottom: "60px" }}
          >
            <GraphYear vegetationIndex={currentIndex} data={dataPerYear[0]} rain_data={dailyRain} monthRange={yearGraphMonth}  />
          </div>
        )}
      </Row>
    </Container>
  );
};

export default GraphPage;
