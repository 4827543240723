import {
  LAYERS_OVERLAY_SUCESS,
  AREA_OF_INTEREST_SUCESS,
  AREA_SURFACE_SUCESS,
  LOADING_BEGIN,
  LOADING_END,
  CLICK_EVENT_STATE_SUCCESS,
  CLICK_EVENT_STATE_FAIL,
  CHECKBOX_STATE_SUCCESS,
  CHECKBOX_STATE_FAIL,
  SET_IS_CHECKED_COUNT_CHECKBOX_SUCCESS,
  SET_IS_CHECKED_COUNT_CHECKBOX_FAIL,
} from "../utils/types";

export function LayersOverlayInizialize(layercontrol) {
  return {
    type: LAYERS_OVERLAY_SUCESS,
    layerControlState: layercontrol,
  };
}

export const AreaOfInterestizialize = (polygon) => async (dispatch) => {
  dispatch({
    type: AREA_OF_INTEREST_SUCESS,
    payload: polygon,
  });
};

export const AreaSurface = (area) => async (dispatch) => {
  dispatch({
    type: AREA_SURFACE_SUCESS,
    payload: area,
  });
};

export const loadingBegin = (type) => (dispatch) => {
  console.log("type", type);
  dispatch({
    type: LOADING_BEGIN,
    payload: type,
  });
};
export const loadingEnd = (type) => (dispatch) => {
  dispatch({
    type: LOADING_END,
    payload: type,
  });
};

export const setClickEventSucess = () => (dispatch) => {
  dispatch({
    type: CLICK_EVENT_STATE_SUCCESS,
  });
};
export const setClickEventFail = () => (dispatch) => {
  dispatch({
    type: CLICK_EVENT_STATE_FAIL,
  });
};

export const setCheckboxLayerState = (id) => (dispatch) => {
  dispatch({
    type: CHECKBOX_STATE_SUCCESS,
    payload: id,
  });
};

export const removeCheckboxLayerState = () => (dispatch) => {
  dispatch({
    type: CHECKBOX_STATE_FAIL,
  });
};

export const setCountCheckboxToChecked = () => (dispatch) => {
  dispatch({
    type: SET_IS_CHECKED_COUNT_CHECKBOX_SUCCESS,
  });
};

export const setCountCheckboxToUnchecked = () => (dispatch) => {
  dispatch({
    type: SET_IS_CHECKED_COUNT_CHECKBOX_FAIL,
  });
};
