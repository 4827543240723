import React, { useRef, useEffect, useState } from "react";
import {
  select,
  line,
  curveCardinal,
  curveCatmullRom,
  scaleLinear,
  scaleTime,
  scaleBand,
  axisBottom,
  axisLeft,
  axisRight,
  zoom,
  timeParse,
  zoomTransform,
} from "d3";
import { Grid } from "@mui/material";
import { extent } from "d3-array";
import "../../../assets/css/graph.css";
import { height } from "dom-helpers";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SlGraph } from "react-icons/sl";
import dayjs from "dayjs";
const useResizeObserver = (ref) => {
  const [dimensions, setdimensions] = useState(null);

  useEffect(() => {
    const observeTarget = ref.current;
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setdimensions(entry.contentRect);
      });
    });
    resizeObserver.observe(observeTarget);
    return () => {
      resizeObserver.unobserve(observeTarget);
    };
  }, [ref]);
  return dimensions;
};

var div = select("body")
  .append("div")
  .attr("class", "txt1")
  .style("opacity", 0)
  .style("display", "none");
const Graph = ({
  vegetationIndex,
  data,
  datapredict,
  indexForecast,
  notifications,
  pattern,
  rainData,
  rain_max,
  rain_min,
  patternAlertsDates,
  ndviForecastAlertsDates
}) => {


  const svgRef = useRef();
  const wrapperRef = useRef();
  const dimensions = useResizeObserver(wrapperRef);
  const [currentZoom, setcurrentZoom] = useState(null);
  const [datagraph, setdatagraph] = useState(null);
  const [datagraphSmooth, setdataGraphSmooth] = useState(null);
  const [dataRangePredict, setdataRangePredict] = useState(null);
  const [dataRangeNotifications, setdataRangeNotifications] = useState(null);
  // const [min, setmin] = useState(null);
  // const [max, setmax] = useState(null);

  const [rainMin, setRainMin] = useState(null);
  const [rainMax, setRainMax] = useState(null);

  const { t } = useTranslation();
  const [observedDataLineState, setobservedDataLineState] = useState(false);
  const [smoothedDataLineState, setsmoothedDataLineState] = useState(true);
  const [forecastDataLineState, setforecastDataLineState] = useState(true);
  const [patternLineState, setpatternLineState] = useState(true);
  const [indexForecastLineState, setIndexForecastLineState] = useState(true);

  const [rainDataLineState, setRainDataLineState] = useState(true);
  const [xScale, setxScale] = useState(null);
  // const [pattern, setpattern] = useState([]);
  useEffect(() => {
    if (!data) return;
    setdatagraph(data.data);
    setdataGraphSmooth(data.data_smoothed);
    // setmin(parseFloat(data.min));
    // setmax(parseFloat(data.max));

    setRainMin(rain_min);
    setRainMax(rain_max);
  }, [data]);

  useEffect(() => {
    if (!datapredict) return;
    if (!datagraph) return;
    if (!datapredict.length > 0) return;
    if (!datagraph.length > 0) return;
    if (!notifications.length > 0) return;
    console.log("datapredict", datapredict);
    const lastDate = dayjs(datagraph[datagraph.length - 1].date);

    // Calculate the date 1 month before and 2 months after the last date
    const startDate = lastDate.subtract(1, "month");
    const endDate = lastDate.add(4, "month");
    // Filter the data to include only those within the date range
    const filteredData = datapredict.filter((data) => {
      const date = dayjs(data.date);
      return date.isAfter(startDate) && date.isBefore(endDate);
    });
    setdataRangePredict(filteredData);
  }, [datapredict, datagraph]);
  useEffect(() => {
    if (!datagraph || !datagraph.length > 0) return;
    if (!notifications || !notifications.length > 0) return;
    const lastDate = dayjs(datagraph[datagraph.length - 1].date);

    // Calculate the date 1 month before and 2 months after the last date
    const startDate = lastDate.subtract(1, "month");
    const endDate = lastDate.add(2, "month");

    const filteredNotificationsData = notifications.filter((data) => {
      const date = dayjs(data.date);
      return date.isAfter(startDate) && date.isBefore(endDate);
    });
    setdataRangeNotifications(filteredNotificationsData);
  }, [datagraph, notifications]);

  useEffect(() => {
    if (!rainData) return;
    setRainMin(rain_min);
    setRainMax(rain_max);
  }, [rainData]);

  useEffect(() => {
    if (!indexForecast) return;
    const svg = select(svgRef.current);

    if (vegetationIndex != "Vegetation Index") {
      svg.selectAll(".ndviForecastLine").style("visibility", "hidden");
      svg.selectAll(".linepattern").style("visibility", "hidden");
      svg
        .selectAll(".myDot.patternAlertDot.ordinaryAlert")
        .attr("class", "myDot ordinaryAlert");
      svg
        .selectAll(".myDot.patternAlertDot.severeAlert")
        .attr("class", "myDot severeAlert");
      svg.selectAll(".forecastdot1.indexForecastAlertDot.severeAlert").style("visibility", "hidden");
      svg.selectAll(".forecastdot1.indexForecastAlertDot.ordinaryAlert").style("visibility", "hidden");

    } else {
      svg.selectAll(".ndviForecastLine").style("visibility", "visible");
      svg.selectAll(".linepattern").style("visibility", "visible");
      svg
        .selectAll(".myDot.severeAlert")
        .attr("class", "myDot patternAlertDot severeAlert ");
      svg
        .selectAll(".myDot.ordinaryAlert")
        .attr("class", "myDot patternAlertDot ordinaryAlert");

      svg.selectAll(".forecastdot1.indexForecastAlertDot.severeAlert").style("visibility", "visible");
      svg.selectAll(".forecastdot1.indexForecastAlertDot.ordinaryAlert").style("visibility", "visible");
    }

  }, [vegetationIndex]);

  const handleClickObservedLegend = () => {
    const svg = select(svgRef.current);
    // Use the state updater function to ensure you work with the latest state
    setobservedDataLineState((prevState) => {
      if (!prevState) {
        svg.selectAll(".line").style("visibility", "visible");
        svg.selectAll(".myDot").style("visibility", "visible");
      } else {
        svg.selectAll(".line").style("visibility", "hidden");
        svg.selectAll(".myDot").style("visibility", "hidden");
      }
      // Return the new state value
      return !prevState;
    });
  };

  const handleClickSmoothedLegend = () => {
    const svg = select(svgRef.current);
    // Use the state updater function to ensure you work with the latest state
    setsmoothedDataLineState((prevState) => {
      if (!prevState) {
        svg.selectAll(".lineSmooth").style("visibility", "visible");
      } else {
        svg.selectAll(".lineSmooth").style("visibility", "hidden");
      }
      return !prevState;
    });
  };
  const handleClickForecastLegend = () => {
    const svg = select(svgRef.current);
    // Use the state updater function to ensure you work with the latest state
    setforecastDataLineState((prevState) => {
      if (!prevState) {
        svg.selectAll(".linepredict").style("visibility", "visible");
      } else {
        svg.selectAll(".linepredict").style("visibility", "hidden");
      }
      return !prevState;
    });
  };

  const handleClickPatternLegend = () => {
    const svg = select(svgRef.current);
    // Use the state updater function to ensure you work with the latest state
    setpatternLineState((prevState) => {
      if (!prevState) {
        svg.selectAll(".linepattern").style("visibility", "visible");
        svg
          .selectAll(".myDot.severeAlert")
          .attr("class", "myDot patternAlertDot severeAlert ");
        svg
          .selectAll(".myDot.ordinaryAlert")
          .attr("class", "myDot patternAlertDot ordinaryAlert");
      } else {
        svg.selectAll(".linepattern").style("visibility", "hidden");
        svg
          .selectAll(".myDot.patternAlertDot.ordinaryAlert")
          .attr("class", "myDot ordinaryAlert");
        svg
          .selectAll(".myDot.patternAlertDot.severeAlert")
          .attr("class", "myDot severeAlert");
      }
      return !prevState;
    });
  };

  const handleClickRainFallLegend = () => {
    const svg = select(svgRef.current);
    setRainDataLineState((prevState) => {
      if (!prevState) {
        svg.selectAll(".linerainfall").style("visibility", "visible");
      } else {
        svg.selectAll(".linerainfall").style("visibility", "hidden");
      }
      return !prevState;
    });
  };

  const handleClickindexForecastLineLegend = () => {
    const svg = select(svgRef.current);
    setIndexForecastLineState((prevState) => {
      if (!prevState) {
        svg.selectAll(".ndviForecastLine").style("visibility", "visible");
        svg.selectAll(".forecastdot1").style("visibility", "visible");
        // svg.selectAll(".forecastdot1").style("visibility", "visible");
      } else {
        svg.selectAll(".ndviForecastLine").style("visibility", "hidden");
        svg.selectAll(".forecastdot1").style("visibility", "hidden");
        // svg.selectAll(".forecastdot1").style("visibility", "hidden");
      }
      return !prevState;
    });
  };


  useEffect(() => {
    if (!datagraph) return;
    const svg = select(svgRef.current);
    const svgContent = svg.select(".content");
    if (!dimensions) return;
    if (!data) return;
    const { width, height } =
      dimensions || wrapperRef.current.getBoundingClientRect();
    if (!dimensions) return;
    if (!data) return;
    let xScale;
    let concatenatedArray = [...datagraph];
    if (pattern && pattern.length > 0) {
      concatenatedArray = [...concatenatedArray, ...pattern];
    }

    if (dataRangePredict && dataRangePredict.length > 0) {
      // Assuming you want to append some other array, let's call it 'anotherData'
      concatenatedArray = [...concatenatedArray, ...dataRangePredict];
    }

    // if (rainData && rainData.length > 0) {
    //   concatenatedArray = [...concatenatedArray, ...rainData];
    // }
    console.log("concatenatedArray", concatenatedArray);
    const { minn, maxx } = concatenatedArray.reduce(
      (acc, obj) => {
        acc.minn = parseFloat(Math.min(acc.minn, obj.value));
        acc.maxx = parseFloat(Math.max(acc.maxx, obj.value));
        return acc;
      },
      { minn: Infinity, maxx: -Infinity }
    );
    console.log("minn", minn);
    console.log("maxx", maxx);
    xScale = scaleTime()
      .domain(extent(concatenatedArray, (d) => new Date(d.date)))
      .range([0, width]);

    if (currentZoom) {
      const newXScale = currentZoom.rescaleX(xScale);
      xScale.domain(newXScale.domain());
    }

    const yScale = scaleLinear()
      .domain([minn - 0.06, maxx + 0.02])
      .range([height, 0]);

    const yScale2 = scaleLinear()
      .domain([rainMin, rainMax + rainMax / 3])
      .range([height, 0]);

    const myLine = line()
      .x((d) => xScale(new Date(d.date)))
      .y((d) => yScale(d.value))
      .curve(curveCatmullRom);

    const ndviForecastLine = line()
      .x((d) => xScale(new Date(d.date)))
      .y((d) => yScale(d.value))
      .curve(curveCatmullRom);

    const myLineSmooth = line()
      .x((d) => xScale(new Date(d.date)))
      .y((d) => yScale(d.NDVI_rolling_mean))
      .curve(curveCatmullRom);


    const rainLine = line()
      .x((d) => xScale(new Date(d.date)))
      .y((d) => yScale2(d.value))
      .curve(curveCatmullRom);

    const xAxis = axisBottom(xScale);
    svg
      .select(".x-axis")
      .attr("color", "#808080")
      .attr("transform", `translate(0, ${height})`)
      .call(xAxis);
    svg
      .select(".x-axis")
      .selectAll("text")
      .attr("transform", "translate(-5,5)rotate(-45)")
      .style("text-anchor", "end");

    svg.select(".y-axis2").attr("transform", `translate(${width},0)`);

    const yAxis = axisLeft(yScale);
    const yAxisRight = axisRight(yScale2).tickFormat(d => d + " mm");
    // const yAxisGrid = axisLeft(yScale).tickSize(-width).tickFormat("");
    svg.select(".y-axis").attr("color", "#808080").call(yAxis);
    svg.select(".y-axis2").attr("color", "#808080").call(yAxisRight);

    svgContent
      .selectAll(".line")
      .data([datagraph])
      .join("path")
      .attr("class", "line")
      .attr("fill", "none")
      .attr("stroke", "#6f9d2f")
      .attr("stroke-width", "2px")
      .style("visibility", observedDataLineState ? "visible" : "hidden")
      .attr("d", myLine);
    svgContent
      .selectAll(".lineSmooth")
      .data([datagraphSmooth])
      .join("path")
      .attr("class", "lineSmooth")
      .attr("fill", "none")
      .attr("stroke", "#ffc107")
      .attr("stroke-width", "2px")
      .style("visibility", smoothedDataLineState ? "visible" : "hidden")
      .attr("d", myLineSmooth);
    if (dataRangePredict) {
      svgContent
        .selectAll(".linepredict")
        .data([dataRangePredict])
        .join("path")
        .attr("class", "linepredict")
        .attr("fill", "none")
        .attr("stroke", "#0051ff")
        .attr("stroke-width", "2px")
        .style("visibility", forecastDataLineState ? "visible" : "hidden")
        .attr("d", myLine);

      //         svgContent
      //         .selectAll(".myDotForecast")
      //         .data(datapredict)
      //         .join("circle")
      //         .attr("class", "myDotForecast")
      //         .attr("stroke", "#0051ff")
      //         .attr("stroke-width", "1px")
      //         .attr("fill", "#0051ff")
      //         .style("visibility", forecastDataLineState ? "visible" : "hidden")
      //         .attr("r", "3")
      //         .attr("cx", (d) => xScale(new Date(d.date)))
      //         .attr("cy", (d) => yScale(d.value))
      //         .on("mouseover", function (event, d) {
      //             select(this).transition().duration("100").attr("r", 5);
      //             //Makes div appear
      //             div
      //                 .transition()
      //                 .duration(100)
      //                 .style("opacity", 1)
      //                 .style("display", "block");
      //             div
      //                 .html(d.cloud_percentage && d.cloud_percentage == -1 ? "Date : " + d.date + "<br>" + t("Value") + ": " + d.value + "<br>" + t("Cloud") + " : " + t("Undefined") : "Date : " + d.date + "<br>" + t("Value") + ": " + d.value + "<br>" + t("Cloud") + " : " + d.cloud_percentage + "%")
      //                 .style("left", event.pageX + 10 + "px")
      //                 .style("top", event.pageY - 30 + "px");
      //         })
      //         .on("mouseout", function (d, i) {
      //             select(this).transition().duration("200").attr("r", 3);
      //             //makes div disappear
      //             div
      //                 .transition()
      //                 .duration("200")
      //                 .style("opacity", 0)
      //                 .style("display", "none");
      //         });
    }
    if (rainData && rainData.length > 0) {
      svgContent
        .selectAll(".linerainfall")
        .data([rainData])
        .call(axisRight(yScale2))
        .join("path")
        .attr("class", "linerainfall")
        .attr("fill", "none")
        .attr("stroke", "#3a93f2")
        .attr("stroke-width", "1.5px")
        .style("visibility", rainDataLineState ? "visible" : "hidden")
        .attr("d", rainLine)
        .attr("y", 140)
        .attr("dy", "171px")
        .attr("dx", "205px")
        .style("text-anchor", "end")
        .text("Rainfall (mm)");
    }


    if (indexForecast && indexForecast.length > 0 && vegetationIndex === "Vegetation Index") {
      svgContent
        .selectAll(".ndviForecastLine")
        .data([indexForecast])
        // .call(axisLeft(yScale))
        .join("path")
        .attr("class", "ndviForecastLine")
        .attr("fill", "none")
        .attr("stroke", "#B0BEC5")
        .attr("stroke-width", "3px")
        .style("visibility", indexForecastLineState && vegetationIndex === "Vegetation Index" ? "visible" : "hidden")
        .attr("d", ndviForecastLine);

      svgContent
        .selectAll(".forecastdot1")
        .data(indexForecast)
        .join("circle")
        .attr("class", (d) =>
          ndviForecastAlertsDates.ordinaryAlerts.includes(d.date) ? "forecastdot1 indexForecastAlertDot ordinaryAlert"
            : ndviForecastAlertsDates.severeAlerts.includes(d.date)
            ? "forecastdot1 indexForecastAlertDot severeAlert"
            : "forecastdot1"
        )
        .attr("stroke", "#B0BEC5")
        .attr("stroke-width", "1px")
        .attr("fill", "#B0BEC5")
        .style("visibility", indexForecastLineState ? "visible" : "hidden")
        .style("opacity", "0")
        .attr("r", "1")
        .attr("cx", (d) => xScale(new Date(d.date)))
        .attr("cy", (d) => yScale(d.value))
        .on("mouseover", function (event, d) {
          select(this).transition().duration("100").attr("r", 5);
          //Makes div appear
          div
            .transition()
            .duration(100)
            .style("opacity", 1)
            .style("display", "block")
          div
            .html(
                "Date : " +
                d.date +
                "<br>" +
                t("NDVI") +
                ": " +
                d.value.toFixed(3) +
                "<br>"
            )
            .style("left", event.pageX + 10 + "px")
            .style("top", event.pageY - 30 + "px");
        })
        .on("mouseout", function (d, i) {
          select(this).transition().duration("200").attr("r", 3);
          //makes div disappear
          div
            .transition()
            .duration("200")
            .style("opacity", 0)
            .style("display", "none");
        });

    }

    if (pattern && pattern.length > 0) {
      svgContent
        .selectAll(".linepattern")
        .data([pattern])
        // .call(axisLeft(yScale))
        .join("path")
        .attr("class", "linepattern")
        .attr("fill", "none")
        .attr("stroke", "#FF3535")
        .attr("stroke-width", "2px")
        .style("visibility", patternLineState ? "visible" : "hidden")
        .attr("d", myLineSmooth);

      //         svgContent
      //         .selectAll(".myDotForecast")
      //         .data(datapredict)
      //         .join("circle")
      //         .attr("class", "myDotForecast")
      //         .attr("stroke", "#0051ff")
      //         .attr("stroke-width", "1px")
      //         .attr("fill", "#0051ff")
      //         .style("visibility", forecastDataLineState ? "visible" : "hidden")
      //         .attr("r", "3")
      //         .attr("cx", (d) => xScale(new Date(d.date)))
      //         .attr("cy", (d) => yScale(d.value))
      //         .on("mouseover", function (event, d) {
      //             select(this).transition().duration("100").attr("r", 5);
      //             //Makes div appear
      //             div
      //                 .transition()
      //                 .duration(100)
      //                 .style("opacity", 1)
      //                 .style("display", "block");
      //             div
      //                 .html(d.cloud_percentage && d.cloud_percentage == -1 ? "Date : " + d.date + "<br>" + t("Value") + ": " + d.value + "<br>" + t("Cloud") + " : " + t("Undefined") : "Date : " + d.date + "<br>" + t("Value") + ": " + d.value + "<br>" + t("Cloud") + " : " + d.cloud_percentage + "%")
      //                 .style("left", event.pageX + 10 + "px")
      //                 .style("top", event.pageY - 30 + "px");
      //         })
      //         .on("mouseout", function (d, i) {
      //             select(this).transition().duration("200").attr("r", 3);
      //             //makes div disappear
      //             div
      //                 .transition()
      //                 .duration("200")
      //                 .style("opacity", 0)
      //                 .style("display", "none");
      //         });
    }
    svgContent
      .selectAll(".myDot")
      .data(datagraph)
      .join("circle")
      .attr("class", (d) =>
        patternAlertsDates.severeAlerts.includes(d.date) && vegetationIndex === "Vegetation Index"
          ? "myDot patternAlertDot severeAlert"
          : patternAlertsDates.ordinaryAlerts.includes(d.date) && vegetationIndex === "Vegetation Index"
            ? "myDot patternAlertDot ordinaryAlert "
            : "myDot"
      )
      .attr("stroke", "#6f9d2f")
      .attr("stroke-width", "1px")
      .attr("fill", "#6f9d2f")
      .style("visibility", observedDataLineState ? "visible" : "hidden")
      .style("opacity", ".6")
      .attr("r", "3")
      .attr("cx", (d) => xScale(new Date(d.date)))
      .attr("cy", (d) => yScale(d.value))
      .on("mouseover", function (event, d) {
        select(this).transition().duration("100").attr("r", 5);
        //Makes div appear
        div
          .transition()
          .duration(100)
          .style("opacity", 1)
          .style("display", "block");
        div
          .html(
            d.cloud_percentage && d.cloud_percentage == -1
              ? "Date : " +
              d.date +
              "<br>" +
              t("Value") +
              ": " +
              d.value +
              "<br>" +
              t("Cloud") +
              " : " +
              t("Undefined")
              : "Date : " +
              d.date +
              "<br>" +
              t("Value") +
              ": " +
              d.value +
              "<br>" +
              t("Cloud") +
              " : " +
              d.cloud_percentage +
              "%"
          )
          .style("left", event.pageX + 10 + "px")
          .style("top", event.pageY - 30 + "px");
      })
      .on("mouseout", function (d, i) {
        select(this).transition().duration("200").attr("r", 3);
        //makes div disappear
        div
          .transition()
          .duration("200")
          .style("opacity", 0)
          .style("display", "none");
      });

    if (dataRangeNotifications && dataRangeNotifications.length > 0) {
      svgContent
        .selectAll(".myDotNote")
        .data(dataRangeNotifications)
        .join("circle")
        .attr("class", "myDotNote")
        .style("visibility", forecastDataLineState ? "visible" : "hidden")

        .attr("stroke", (d) => {
          if (d && typeof d.abs === "number") {
            return d.abs > 0.2 ? "#f44336" : "#ff9800";
          }
          return "";
        })
        .attr("stroke-width", "3px")
        .attr("fill", (d) => {
          if (d && typeof d.abs === "number") {
            return d.abs > 0.2 ? "#f44336" : "#ff9800";
          }
          return "";
        })
        .attr("r", "3")
        .attr("cx", (d) => xScale(new Date(d.date)))
        .attr("cy", (d) => yScale(d.value))
        .on("mouseover", function (event, d) {
          select(this).transition().duration("100").attr("r", 5);
          //Makes div appear
          div
            .transition()
            .duration(100)
            .style("opacity", 1)
            .style("display", "block");
          div
            .html(
              d.date
                ? "Date : " + d.date + "<br>" + t("Value") + ": " + d.value
                : ""
            )
            .style("left", event.pageX + 10 + "px")
            .style("top", event.pageY - 30 + "px");
        })
        .on("mouseout", function (d, i) {
          select(this).transition().duration("200").attr("r", 3);
          //makes div disappear
          div
            .transition()
            .duration("200")
            .style("opacity", 0)
            .style("display", "none");
        });
    } else {
      // Clear all points if notifications is empty
      svgContent.selectAll(".myDotNote").remove(); // Remove all points with the class "myDotNote"
    }
    const zoomBehavior = zoom()
      .translateExtent([
        [0, 0],
        [width, height],
      ])
      .scaleExtent([0.3, 100])
      .on("zoom", (event) => {
        const zoomState = event.transform;
        setcurrentZoom(zoomState);
      });
    svg.call(zoomBehavior);
  }, [
    svgRef,
    datagraph,
    datagraphSmooth,
    dimensions,
    currentZoom,
    observedDataLineState,
    datapredict,
    indexForecast,
    notifications,
    pattern,
    rainData,
    rainMax,
    rainMin,
    forecastDataLineState,
    ndviForecastAlertsDates
  ]);

  return (
    <>
      <Grid
        item
        xs={12}
        container
        spacing={1}
        className="mt-3 mb-1 d-flex justify-content-center"
      >
        <Grid
          item
          className="d-flex align-items-center task-legend"
          onClick={() => handleClickObservedLegend()}
        >
          <SlGraph
            style={{
              color: observedDataLineState ? "#6f9d2f" : "#cccccc",
              fontSize: "2rem",
              padding: ".1rem",
              border: "1px grey solid",
              "border-radius": "100%",
            }}
          />
          <span
            style={{ color: observedDataLineState ? "#000000" : "#cccccc" }}
          >
            Observed {vegetationIndex}
          </span>
        </Grid>
        <Grid
          item
          className="d-flex align-items-center task-legend"
          onClick={() => handleClickSmoothedLegend()}
        >
          <SlGraph
            style={{
              color: smoothedDataLineState ? "#ffc107" : "#cccccc",
              fontSize: "2rem",
              padding: ".1rem",
              border: "1px grey solid",
              "border-radius": "100%",
            }}
          />
          <span
            style={{ color: smoothedDataLineState ? "#000000" : "#cccccc" }}
          >
            Smoothed {vegetationIndex}
          </span>
        </Grid>

        {pattern.length > 0 && vegetationIndex === "Vegetation Index" && (
          <Grid
            item
            className="d-flex align-items-center task-legend"
            onClick={() => handleClickPatternLegend()}
          >
            <SlGraph
              style={{
                color: patternLineState ? "#FF3535" : "#cccccc",
                fontSize: "2rem",
                padding: ".1rem",
                border: "1px grey solid",
                "border-radius": "100%",
              }}
            />
            <span style={{ color: patternLineState ? "#000000" : "#cccccc" }}>
              Pattern
            </span>
          </Grid>
        )}

        {rainData.length > 0 && (
          <Grid
            item
            className="d-flex align-items-center task-legend"
            onClick={() => handleClickRainFallLegend()}
          >
            <SlGraph
              style={{
                color: rainDataLineState ? "#1f77b4" : "#cccccc",
                fontSize: "2rem",
                padding: ".1rem",
                border: "1px grey solid",
                "border-radius": "100%",
              }}
            />
            <span style={{ color: rainDataLineState ? "#000000" : "#cccccc" }}>
              Rainfall
            </span>
          </Grid>
        )}

        {dataRangePredict && dataRangePredict.length > 0 && (
          <Grid
            item
            className="d-flex align-items-center task-legend"
            onClick={() => handleClickForecastLegend()}
          >
            <SlGraph
              style={{
                color: forecastDataLineState ? "#0051ff" : "#cccccc",
                fontSize: "2rem",
                padding: ".1rem",
                border: "1px grey solid",
                "border-radius": "100%",
              }}
            />
            <span
              style={{ color: forecastDataLineState ? "#000000" : "#cccccc" }}
            >
              {vegetationIndex} Forecast M1
            </span>
          </Grid>
        )}

        {indexForecast.length > 0 && vegetationIndex === "Vegetation Index" && (
          <Grid
            item
            className="d-flex align-items-center task-legend"
            onClick={() => handleClickindexForecastLineLegend()}
          >
            <SlGraph
              style={{
                color: indexForecastLineState ? "#B0BEC5" : "#cccccc",
                fontSize: "2rem",
                padding: ".1rem",
                border: "1px grey solid",
                "border-radius": "100%",
              }}
            />
            <span style={{ color: indexForecastLineState ? "#000000" : "#cccccc" }}>
              Forecast M2
            </span>
          </Grid>
        )}
      </Grid>

      <div
        className="col-md-12 svg-container"
        ref={wrapperRef}
        style={datagraph ? { marginBottom: "40px" } : {}}
      >
        {datagraph ? (
          <svg ref={svgRef} className="graphIndex">
            <defs>
              <clipPath id="MygraphPanel">
                <rect x="0" y="0" width="100%" height="100%" />
              </clipPath>
            </defs>
            <g className="content" clipPath="url(#MygraphPanel)"></g>
            <g className="x-axis" />
            <g className="x-axis-grid" />
            <g className="y-axis" />
            <g className="y-axis2" />
            {/* <g className="y-axis-grid" /> */}
          </svg>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Graph;
