import {
  HISTOGRAM_SUCCESS,
  HISTOGRAM_FAIL,
  SET_HISTOGRAM_INTERVAL,
  SET_HISTOGRAM_LOAD_SUCCESS,
  SET_HISTOGRAM_LOAD_FAIL,
} from "../utils/types";
const initialState = {
  histogram: [],
  min: null,
  max: null,
  tif_path: null,
  loading: true,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HISTOGRAM_SUCCESS:
      return {
        ...state,
        histogram: payload,
        min: payload.percentile[0],
        max: payload.percentile[1],
        tif_path: payload.tif_file,
        loading: false,
      };
    case HISTOGRAM_FAIL:
      return {
        ...state,
        histogram: [],
        min: null,
        max: null,
        tif_path: null,
        loading: true,
      };
    case SET_HISTOGRAM_INTERVAL:
      console.log("payload", payload);
      return {
        ...state,
        min: payload[0],
        max: payload[1],
      };
    case SET_HISTOGRAM_LOAD_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case SET_HISTOGRAM_LOAD_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
}
