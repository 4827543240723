export const CONST_LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const CONST_LOGIN_FAIL = "LOGIN_FAIL";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const ACTIVATION_SUCCESS = "ACTIVATION_SUCCESS";
export const ACTIVATION_FAIL = "ACTIVATION_FAIL";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAIL = "LOAD_USER_FAIL";
export const AYTHENTICATED_SUCCESS = "AYTHENTICATED_SUCCESS";
export const AYTHENTICATED_FAIL = "AYTHENTICATED_FAIL";
export const LOGOUT = "LOGOUT";
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_CONFIRM_FAIL = "PASSWORD_RESET_CONFIRM_FAIL";
export const PASSWORD_RESET_CONFIRM_SUCCESS = "LOGOUT";

export const LAYERS_OVERLAY_SUCESS = "LAYERS_OVERLAY_SUCESS";
export const LAYERS_OVERLAY_FAIL = "LAYERS_OVERLAY_FAIL";

export const AREA_OF_INTEREST_SUCESS = "AREA_OF_INTEREST_SUCESS";
export const AREA_OF_INTEREST_FAIL = "AREA_OF_INTEREST_FAIL";
export const AREA_SURFACE_SUCESS = "AREA_SURFACE_SUCESS";
export const AREA_SURFACE_FAIL = "AREA_SURFACE_FAIL";

export const NDVI_SUCESS = "NDVI_SUCESS";
export const NDVI_FAIL = "NDVI_FAIL";
export const DETAIL_TASK_SUCESS = "DETAIL_TASK_SUCESS";
export const DETAIL_TASK_FAIL = "DETAIL_TASK_FAIL";
export const LOADING_BEGIN = "LOADING_BEGIN";
export const LOADING_END = "LOADING_END";

export const SELECTED_DATE_SUCCESS = "SELECTED_DATE_SUCCESS";
export const SELECTED_DATE_FAIL = "SELECTED_DATE_FAIL";

export const SELECTED_DATE_INITIAL_SUCCESS = "SELECTED_DATE_INITIAL_SUCCESS";
export const SELECTED_DATE_INITIAL_FAIL = "SELECTED_DATE_INITIAL_FAIL";

export const ACCUSATION_DATES_SUCCESS = "ACCUSATION_DATES_SUCCESS";
export const ACCUSATION_DATES_FAIL = "ACCUSATION_DATES_FAIL";
export const CLICK_EVENT_STATE_SUCCESS = "CLICK_EVENT_STATE_SUCCESS";
export const CLICK_EVENT_STATE_FAIL = "CLICK_EVENT_STATE_FAIL";

export const UPDATE_USER_INFORMATIONS_FAIL = "UPDATE_USER_INFORMATIONS_FAIL";
export const UPDATE_USER_INFORMATIONS_SUCCESS =
  "UPDATE_USER_INFORMATIONS_SUCCESS";
export const UPDATE_USER_PASSWORD_FAIL = "UPDATE_USER_PASSWORD_FAIL";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
// export const NDVI_URL_SUCCESS = 'NDVI_URL_SUCCESS';
// export const NDVI_URL_FAIL = 'NDVI_URL_FAIL';
export const SET_DETAILS_TASK_FORM_SUCCESS = "SET_DETAILS_TASK_FORM_SUCCESS";
export const SET_DETAILS_TASK_FORM_FAIL = "SET_DETAILS_TASK_FORM_FAIL";

export const SNACKBAR_SHOW = "SNACKBAR_SHOW";
export const SNACKBAR_HIDE = "SNACKBAR_HIDE";

export const REQUEST_EXIST = "REQUEST_EXIST";
export const REQUEST_EMPTY = "REQUEST_EMPTY";
export const HIGH_RES_SERVICE_AVAILABLE = "HIGH_RES_SERVICE_AVAILABLE";
export const HIGH_RES_SERVICE_NOT_AVAILABLE = "HIGH_RES_SERVICE_NOT_AVAILABLE";

export const NOTE_SUCCESS = "NOTE_SUCCESS";
export const NOTE_FAIL = "NOTE_FAIL";
export const NOTE_NAMES_SUCCESS = "NOTE_NAMES_SUCCESS";
export const NOTE_NAMES_FAIL = "NOTE_NAMES_FAIL";
export const SET_NOTE_NAME_SUCCESS = "SET_NOTE_NAME_SUCCESS";
export const SET_NOTE_NAME_FAIL = "SET_NOTE_NAME_FAIL";

export const FETCH_SENSOR_DATA_FAIL = "FETCH_SENSOR_DATA_FAIL";

export const GRAPH_START_END_DATE_SUCCESS = "GRAPH_START_END_DATE_SUCCESS";
export const GRAPH_START_END_DATE_END_FAIL = "GRAPH_START_END_DATE_END_FAIL";

export const GRAPH_DATA_ARRAY_SUCCESS = "GRAPH_DATA_ARRAY_SUCCESS";
export const GRAPH_DATA_ARRAY_FAIL = "GRAPH_DATA_ARRAY_FAIL";

export const CHECKBOX_STATE_SUCCESS = "CHECKBOX_STATE_SUCCESS";
export const CHECKBOX_STATE_FAIL = "CHECKBOX_STATE_FAIL";

export const SELECTED_PROJECT_SUCCESS = "SELECTED_PROJECT_SUCCESS";
export const SELECTED_PROJECT_FAIL = "SELECTED_PROJECT_FAIL";

export const SET_TREE_COUNT_VALUE_SUCCESS = "SET_TREE_COUNT_VALUE_SUCCESS";
export const SET_TREE_COUNT_VALUE_FAIL = "SET_TREE_COUNT_VALUE_FAIL";

export const SET_TREE_COUNT_ARRAY_SUCCESS = "SET_TREE_COUNT_ARRAY_SUCCESS";
export const SET_TREE_COUNT_ARRAY_FAIL = "SET_TREE_COUNT_ARRAY_FAIL";

export const SET_IS_CHECKED_COUNT_CHECKBOX_SUCCESS =
  "SET_IS_CHECKED_COUNT_CHECKBOX_SUCCESS";
export const SET_IS_CHECKED_COUNT_CHECKBOX_FAIL =
  "SET_IS_CHECKED_COUNT_CHECKBOX_FAIL";

export const TASK_IMAGE_SIDEBAR_SUCCESS = "TASK_IMAGE_SIDEBAR_SUCCESS";
export const TASK_IMAGE_SIDEBAR_FAIL = "TASK_IMAGE_SIDEBAR_FAIL";

export const TASK_WEATHER_SUCCESS = "TASK_WEATHER_SUCCESS";
export const TASK_WEATHER_FAIL = "TASK_WEATHER_FAIL";

export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAIL = "ADD_EVENT_FAIL";

export const INSECT_LIST_SUCESS = "INSECT_LIST_SUCESS";
export const INSECT_LIST_FAIL = "INSECT_LIST_FAIL";

export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAIL = "DELETE_EVENT_FAIL";

export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const TASKS_GRAPH_DATA_COMPARE_SUCCESS =
  "TASKS_GRAPH_DATA_COMPARE_SUCCESS";
export const TASKS_GRAPH_DATA_COMPARE_FAIL = "TASKS_GRAPH_DATA_COMPARE_FAIL";

export const USER_HISTORY_SUCCESS = "USER_HISTORY_SUCCESS";
export const USER_HISTORY_FAIL = "USER_HISTORY_FAIL";
export const USER_HISTORY_STATISTICS_SUCCESS =
  "USER_HISTORY_STATISTICS_SUCCESS";
export const USER_HISTORY_STATISTICS_FAIL = "USER_HISTORY_STATISTICS_FAIL";

export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";

export const USER_NOTIFICATIONS_SUCCESS = "USER_NOTIFICATIONS_SUCCESS";
export const USER_NOTIFICATIONS_FAIL = "USER_NOTIFICATIONS_FAIL";
export const USER_NOTIFICATIONS_HEAD_SUCCESS =
  "USER_NOTIFICATIONS_HEAD_SUCCESS";
export const USER_NOTIFICATIONS_HEAD_FAIL = "USER_NOTIFICATIONS_HEAD_FAIL";

export const KEEP_ME_SIGN_IN_CHECKED = "KEEP_ME_SIGN_IN_CHECKED";
export const SET_REFRESH_INTERVAL = "SET_REFRESH_INTERVAL";
export const CLEAR_REFRESH_INTERVAL = "CLEAR_REFRESH_INTERVAL";

export const HISTOGRAM_SUCCESS = "HISTOGRAM_SUCCESS";
export const HISTOGRAM_FAIL = "HISTOGRAM_FAIL";

export const SET_HISTOGRAM_INTERVAL = "SET_HISTOGRAM_INTERVAL";
export const SET_HISTOGRAM_LOAD_SUCCESS = "SET_HISTOGRAM_LOAD_SUCCESS";
export const SET_HISTOGRAM_LOAD_FAIL = "SET_HISTOGRAM_LOAD_FAIL";

export const IRRIGATION_CALENDAR_SUCESS = 'IRRIGATION_CALENDAR_SUCESS';
export const IRRIGATION_CALENDAR_FAIL = 'IRRIGATION_CALENDAR_FAIL';

