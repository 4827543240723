import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "react-bootstrap/Button";
import Typography from "@mui/material/Typography";
import Row from "react-bootstrap/esm/Row";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCulture,
  getDensityAll,
  getIrrigation,
  getTexture,
  getVarietyAll,
  getVariety,
  getDensity,
} from "../../../actions/project";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { edit_task } from "../../../actions/task";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const EditTaskPopup = ({ open, handleClose }) => {
  const [opensnack, setopensnack] = useState(false);
  const [message, setmessage] = useState(null);
  const [isAgeValid, setIsAgeValid] = useState(false);
  const [isEfficiencyValid, setIsEfficiencyValid] = useState(false);
  const [type, settype] = useState(null);

  const id_task = useSelector((state) => state.mapReducer.taskIdDetails);
  const Ename = useSelector((state) => state.mapReducer.taskNameDetails);
  const Eage = useSelector((state) => state.mapReducer.ageDetails);
  const Eculture = useSelector((state) => state.mapReducer.cultureDetails);
  const Etexture = useSelector((state) => state.mapReducer.textureDetails);
  const Edensity = useSelector((state) => state.mapReducer.densityDetails);
  const Eirrigation = useSelector(
    (state) => state.mapReducer.irrigationTypeDetails
  );
  const Esalinity = useSelector((state) => state.mapReducer.salinityDetails);
  const Eefficiency = useSelector(
    (state) => state.mapReducer.efficiencyDetails
  );
  const Evariety = useSelector((state) => state.mapReducer.varietyDetails);

  const [listCultureEdit, setlistCultureEdit] = useState(null);
  const [listTextureEdit, setlistTextureEdit] = useState(null);
  const [listIrrigationEdit, setlistIrrigationEdit] = useState(null);
  const [listVarietyEdit, setlistVarietyEdit] = useState(null);
  const [listDenstyEdit, setlistDenstyEdit] = useState(null);

  const [listVariety, setlistVariety] = useState(null);
  const [listDensity, setlistDensity] = useState(null);

  // const [selectedCulture, setselectedCulture] = useState(null)
  // const [selectedIrrigation, setselectedIrrigation] = useState(second)

  const handleCloseSnackBar = () => {
    setopensnack(false);
  };
  const handleOpenSnackBar = (type, msg) => {
    setopensnack(true);
    settype(type);
    setmessage(msg);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };
  const [formData, setFormData] = useState({
    name: "",
    age: "",
    culture: "",
    texture: "",
    salinity: "",
    density: "",
    irrigation: "",
    efficiency: "",
    variety: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [DensityArray, setDensityArray] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const {
    name,
    age,
    culture,
    texture,
    salinity,
    density,
    irrigation,
    efficiency,
    variety,
  } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t } = useTranslation();

  useEffect(() => {
    if (!listCultureEdit) {
      dispatch(getCulture()).then((res) => {
        setlistCultureEdit(res.data);
      });
    }
  }, []);
  useEffect(() => {
    if (!listCultureEdit) {
      dispatch(getTexture()).then((res) => {
        setlistTextureEdit(res.data);
      });
    }
  }, []);
  useEffect(() => {
    if (!listIrrigationEdit) {
      dispatch(getIrrigation()).then((res) => {
        setlistIrrigationEdit(res.data);
      });
    }
  }, []);
  useEffect(() => {
    if (!listVarietyEdit) {
      dispatch(getVarietyAll()).then((res) => {
        setlistVarietyEdit(res.data);
      });
    }
  }, []);
  useEffect(() => {
    if (!listDenstyEdit) {
      dispatch(getDensityAll()).then((res) => {
        setlistDenstyEdit(res.data);
      });
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    let isValid = true;
    setIsAgeValid(false);
    setIsEfficiencyValid(false);

    if (age <= 0) {
      setIsAgeValid(true);
      isValid = false;
    }
    if (efficiency < 1 || efficiency > 100) {
      setIsEfficiencyValid(true);
      isValid = false;
    }

    if (isValid) {
      dispatch(
        edit_task(
          id_task,
          name,
          age,
          culture,
          texture,
          salinity,
          density,
          irrigation,
          efficiency,
          variety
        )
      ).then((res) => {
        window.location.reload();
      });
    } else {
    }
  };

  useEffect(() => {
    if (
      Eculture !== null ||
      Eirrigation !== null ||
      Edensity != null ||
      Ename != null ||
      Eage != null ||
      Evariety != null ||
      Eage != null
    ) {
      setFormData({
        ...formData,
        culture: Eculture,
        irrigation: Eirrigation,
        density: Edensity,
        name: Ename,
        variety: Evariety,
        age: Eage,
        texture: Etexture,
        salinity: Esalinity,
        efficiency: Eefficiency,
      });
    }
  }, [Ename]);

  useEffect(() => {
    if (!culture) {
      setlistVariety(null);
      setlistDensity(null);
      return;
    }
    dispatch(getVariety(culture)).then((res) => {
      setlistVariety(res.data);
    });
    dispatch(getDensity(culture)).then((res) => {
      setlistDensity(res.data);
    });
  }, [culture]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="custom-modal">
            <Typography
              id="transition-modal-title"
              variant="h5"
              component="h2"
              className="text-center"
            >
              {t(
                "Please fill out the following information to edit your field"
              )}
            </Typography>
            <Form onSubmit={(e) => onSubmit(e)}>
              <Row className="mt-3 mb-3">
                <div className="col-md-6">
                  <FormControl sx={{ m: 1, minWidth: "100%" }} size="small">
                    <TextField
                      fullWidth
                      id="outlined-name-input"
                      label={t("Field name")}
                      type="text"
                      name="name"
                      size="small"
                      value={name}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </FormControl>
                </div>
                <div className="col-md-6">
                  <FormControl
                    required
                    sx={{ m: 1, minWidth: "100%" }}
                    size="small"
                  >
                    <InputLabel id="demo-select-small-label">
                      {t("Culture")}
                    </InputLabel>

                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={culture}
                      label="Culture"
                      fullWidth
                      onChange={(e) => onChange(e)}
                      name="culture"
                      required
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {listCultureEdit &&
                        listCultureEdit.length > 0 &&
                        listCultureEdit.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            selected={item.id === Eculture}
                          >
                            {item.name_fr}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </Row>
              <Row>
                <div className="col-md-6">
                  <FormControl sx={{ m: 1, minWidth: "100%" }} size="small">
                    <InputLabel id="demo-select-small-label">
                      {t("Variety")}
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={variety}
                      label="Variety"
                      fullWidth
                      onChange={(e) => onChange(e)}
                      name="variety"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {listVarietyEdit && listVarietyEdit.length > 0 ? (
                        listVarietyEdit.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            selected={item.id === Evariety}
                          >
                            {item.name_fr}
                          </MenuItem>
                        ))
                      ) : listVarietyEdit && listVarietyEdit.length > 0 ? (
                        listVarietyEdit.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            selected={item.id === Evariety}
                          >
                            {item.name_fr}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">
                          <em>{t("No varieties available")}</em>
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-6">
                  <FormControl sx={{ m: 1, minWidth: "100%" }} size="small">
                    <InputLabel id="demo-select-small-label">
                      {t("Density")}
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={density}
                      label="density"
                      fullWidth
                      onChange={(e) => onChange(e)}
                      name="density"
                    >
                      {listDensity && listDensity.length > 0 ? (
                        listDensity.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            selected={item.id === Edensity}
                          >
                            {item.name_fr}
                          </MenuItem>
                        ))
                      ) : listDenstyEdit && listDenstyEdit.length > 0 ? (
                        listDenstyEdit.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            selected={item.id === Edensity}
                          >
                            {item.name_fr}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">
                          <em>{t("No densities available")}</em>
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
              </Row>
              <Row className="mt-3 mb-3">
                <div className="col-md-6">
                  <FormControl
                    required
                    sx={{ m: 1, minWidth: "100%" }}
                    size="small"
                  >
                    <TextField
                      fullWidth
                      id="outlined-name-input"
                      label="Age"
                      type="number"
                      name="age"
                      size="small"
                      value={age}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </FormControl>
                </div>
              </Row>
              <Row>
                <div className="col-md-12">
                  <FormControl>
                    <Typography variant="h6" component="h2" className="">
                      {t("Floor")}
                    </Typography>
                  </FormControl>
                </div>
              </Row>
              <Row>
                <div className="col-md-6">
                  <FormControl
                    required
                    sx={{ m: 1, minWidth: "100%" }}
                    size="small"
                  >
                    <InputLabel id="demo-select-small-label">
                      {t("Texture")}
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={texture}
                      label="Texture"
                      fullWidth
                      onChange={(e) => onChange(e)}
                      name="texture"
                      required
                    >
                      {listTextureEdit &&
                        listTextureEdit.length > 0 &&
                        listTextureEdit.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name_fr}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-6">
                  <FormControl
                    required
                    sx={{ m: 1, minWidth: "100%" }}
                    size="small"
                  >
                    <InputLabel id="demo-select-small-label">
                      {t("Water Salinity")}
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={salinity}
                      label="Water Salinity"
                      fullWidth
                      onChange={(e) => onChange(e)}
                      name="salinity"
                      required
                    >
                      <MenuItem value="Low (0-2)">
                        <em>Low (0-2)</em>
                      </MenuItem>
                      <MenuItem value="Medium (2-5)">
                        <em>Medium (2-5)</em>
                      </MenuItem>
                      <MenuItem value="High (>5)">
                        <em>High ({">"}5)</em>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Row>
              <Row>
                <div className="col-md-12">
                  <FormControl>
                    <Typography variant="h6" component="h2" className="">
                      {t("Irrigation system")}
                    </Typography>
                  </FormControl>
                </div>
              </Row>
              <Row>
                <div className="col-md-6">
                  <FormControl
                    required
                    sx={{ m: 1, minWidth: "100%" }}
                    size="small"
                  >
                    <InputLabel id="demo-select-small-label">
                      {t("Type")}
                    </InputLabel>
                    {irrigation && (
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={irrigation}
                        label="Type"
                        fullWidth
                        onChange={(e) => onChange(e)}
                        name="irrigation"
                        required
                      >
                        {listIrrigationEdit &&
                          listIrrigationEdit.length > 0 &&
                          listIrrigationEdit.map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.id}
                              selected={item.id === Eirrigation}
                            >
                              {item.name_fr}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  </FormControl>
                </div>
                <div className="col-md-6">
                  <FormControl
                    required
                    sx={{ m: 1, minWidth: "100%" }}
                    size="small"
                  >
                    <TextField
                      fullWidth
                      id="outlined-name-input"
                      label={t("Efficiency (1-100)%")}
                      type="number"
                      name="efficiency"
                      size="small"
                      value={efficiency}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </FormControl>
                </div>
              </Row>

              <Row className="mt-3">
                <div className="col-md-3"></div>
                <div className="col-md-6 text-center d-flex justify-content-around">
                  {/* <CustomButton type="submit" color='IconColor' size="medium" startIcon={<IoIosAdd />}>Draw Field</CustomButton> */}
                  <Button className="btn btn-login" type="submit">
                    {t("Edit survey")}
                  </Button>
                  <Button variant="light" onClick={handleClose}>
                    {t("Cancel")}
                  </Button>

                  {/* <CustomButton startIcon={<MdOutlineCancel />} onClick={handleClose}>Cancel</CustomButton> */}
                </div>
              </Row>
            </Form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default EditTaskPopup;
