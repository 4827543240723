import React, { useEffect, useState, useRef } from "react";
import GeoRasterLayer from "georaster-layer-for-leaflet";
import { colormap } from "../../../utils/colorMap.js";
import { useSelector } from "react-redux";
import L from "leaflet";

const MapView = ({ Map }) => {
  var parse_georaster = require("georaster");

  const tif_path = useSelector((state) => state.histogramReducer.tif_path);
  const min = useSelector((state) => state.histogramReducer.min);
  const max = useSelector((state) => state.histogramReducer.max);
  const loading = useSelector((state) => state.histogramReducer.loading);
  const [georaster, setGeoraster] = useState(null);
  const newLayerRef = useRef(null); // Reference for the new layer

  useEffect(() => {
    if (!tif_path) {
      removeCurrentLayer(); // Remove the old layer when tif_path is null
      return;
    }
    if (!Map || loading) return;

    const fetchAndSetLayer = async () => {
      try {
        const fullpath = `${process.env.REACT_APP_API_URL}${tif_path}`;
        const response = await fetch(fullpath);
        const arrayBuffer = await response.arrayBuffer();
        const parsedGeoraster = await parse_georaster(arrayBuffer);

        setGeoraster(parsedGeoraster);

        // Create the new layer
        const newLayer = createGeoRasterLayer(parsedGeoraster, min, max);
        newLayerRef.current = newLayer; // Store the new layer reference

        // Check if the new layer is ready
        if (newLayer.getElement() && newLayer.getElement().complete) {
          addNewLayer();
        } else {
          newLayer.once('load', addNewLayer);
        }
      } catch (error) {
        console.error("Error fetching or parsing GeoTIFF:", error);
      }
    };

    fetchAndSetLayer();
  }, [tif_path, loading,min,max]);

  useEffect(() => {
    if (!georaster || !Map || !min || !max || loading) return;

    if (newLayerRef.current) {
      removeCurrentLayer(() => {
        addNewLayer();
      });
    }
  }, [min, max, georaster, loading]);

  const addNewLayer = () => {
    if (newLayerRef.current) {
      Map.addLayer(newLayerRef.current);
      setTimeout(() => {
        newLayerRef.current = null; // Clear the reference after adding
      }, 0);
    }
  };

  const removeCurrentLayer = (callback) => {
    Map.eachLayer((layer) => {
      if (layer.options.name === "geoRaster") {
        Map.removeLayer(layer);
      }
    });
    if (callback) callback();
  };

  const createGeoRasterLayer = (georaster, min, max) => {
    return new GeoRasterLayer({
      georaster: georaster,
      opacity: 1,
      name: "geoRaster",
      pixelValuesToColorFn: (values) => {
        const ndviValue = values[0];

        if (isNaN(ndviValue)) {
          return "";
        }

        const clampedValue = Math.min(Math.max(ndviValue, min), max);
        const normalizedValue = (clampedValue - min) / (max - min);
        const colormapIndex = Math.floor(
          normalizedValue * (colormap.rdylgn.length - 1)
        );
        const color = colormap.rdylgn[colormapIndex];
        if (!color) {
          return "rgb(165, 0, 38, 255)";
        }
        return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
      },
      resolution: 128,
      debugLevel: 1,
    });
  };

  return null; // Or return appropriate JSX if needed
};

export default MapView;
