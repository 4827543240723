import { Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Histogram from "./histogram";
import { useDispatch, useSelector } from "react-redux";
import {
  getHistogramRange,
  getHistogramStatic,
} from "../../../actions/histogram";
import { SET_HISTOGRAM_INTERVAL } from "../../../utils/types";

const HistogramTab = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);
  const [staticMin, setstaticMin] = useState(null);
  const [staticMax, setstaticMax] = useState(null);
  const density_id = useSelector((state) => state.mapReducer.densityDetails);
  const index = useSelector((state) => state.mapReducer.checkboxLayers);
  const minPercentile = useSelector((state) => state.histogramReducer.min);
  const maxPercentile = useSelector((state) => state.histogramReducer.max);
  const histogram = useSelector((state) => state.histogramReducer.histogram);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    console.log("histogram.length", histogram.length);
    if (!histogram.percentile) return;
    if (!index) return;
    if (!density_id) return;
    console.log("clicked");
    if (activeTab === 2 && index && density_id) {
      dispatch(getHistogramStatic(index, density_id));
    }
    if (activeTab == 1) {
      const interval = [
        parseFloat(histogram.percentile[0]),
        parseFloat(histogram.percentile[1]),
      ];
      dispatch({
        type: SET_HISTOGRAM_INTERVAL,
        payload: interval,
      });
    }
  }, [histogram, activeTab, index, density_id, dispatch]);
  return (
    <React.Fragment>
      <Grid container sx={{ flex: 1, p: 1 }}>
        <ul
          className="nav nav-tabs nav-tabs-histogram nav-justified w-100"
          role="tablist"
        >
          <li className="nav-item">
            <a
              className={`nav-link navlink ${activeTab === 1 ? "active" : ""}`}
              data-bs-toggle="tab"
              href="#dynamic"
              role="tab"
              onClick={() => handleTabClick(1)}
            >
              <span className="d-none d-sm-block">{t("DYNAMIC")}</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link navlink ${activeTab === 2 ? "active" : ""}`}
              data-bs-toggle="tab"
              href="#static"
              role="tab"
              onClick={() => handleTabClick(2)}
            >
              <span className="d-none d-sm-block">{t("STATIC")}</span>
            </a>
          </li>
        </ul>
      </Grid>
      <Grid>
        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab === 1 ? "active" : ""}`}
            id="dynamic"
            role="tabpanel"
          >
            <Grid>{activeTab === 1 ? <Histogram /> : ""}</Grid>
          </div>
          <div
            className={`tab-pane ${activeTab === 2 ? "active" : ""}`}
            id="static"
            role="tabpanel"
          >
            <Grid>
              {activeTab === 2 ? (
                <Histogram tab={1} staticMin={-1} staticMax={1} />
              ) : (
                ""
              )}
            </Grid>
          </div>
        </div>
      </Grid>
    </React.Fragment>
  );
};

export default HistogramTab;
