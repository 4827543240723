import React, { useRef, useEffect, useState } from "react";
import {
    select,
    line,
    curveLinear,
    scaleLinear,
    scaleTime,
    axisBottom,
    axisLeft,
    zoom,
} from "d3";
import { extent } from "d3-array";
import "../../assets/css/graph.css";
import { useTranslation } from "react-i18next";
import { MdShowChart } from "react-icons/md";
import { Grid } from '@mui/material';
import "../../assets/scss/compare.scss";
import { SlGraph } from "react-icons/sl";

const useResizeObserver = (ref) => {
    const [dimensions, setdimensions] = useState(null);

    useEffect(() => {
        const observeTarget = ref.current;
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                setdimensions(entry.contentRect);
            });
        });
        resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);
    return dimensions;
};

var div = select("body")
    .append("div")
    .attr("class", "txt1")
    .style("opacity", 0)
    .style("display", "none");

const Graph = ({ vegetationIndex, data }) => {
    const svgRef = useRef();
    const wrapperRef = useRef();
    const dimensions = useResizeObserver(wrapperRef);
    const [currentZoom, setcurrentZoom] = useState(null);
    const [datagraph, setdatagraph] = useState(null);
    const [min, setmin] = useState(null)
    const [max, setmax] = useState(null)
    const { t } = useTranslation();
    const [observedDataLineState, setobservedDataLineState] = useState(false)
    const [smoothedDataLineState, setsmoothedDataLineState] = useState(true)
    const [lineVisibility, setLineVisibility] = useState([]);

    const colors = ['#6929c4', '#1192e8', '#005d5d',
        '#9f1853', '#fa4d56', '#570408', '#198038',
        '#002d9c', '#ee538b', '#b28600', '#009d9a',
        '#012749', '#8a3800', '#a56eff', '#03fc49',
        '#03cefc', '#fc03f8', '#fc0339', '#fc8003',
        '#000000', '#bdbd00', '#00ffdd'];
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };
    const shuffledColors = shuffleArray(colors);

    useEffect(() => {
        if (!data) return
        const minimum = data.reduce((min, item) => {
            const itemMin = item.min;
            return min === null || (itemMin !== null && itemMin < min) ? itemMin : min;
        }, null);
        setmin(minimum)
        const maximum = data.reduce((max, item) => {
            const itemMax = item.max;
            return max === null || (itemMax !== null && itemMax > max) ? itemMax : max;
        }, null);
        setmax(maximum)
        const dataWithColors = data.map((item, index) => ({
            data: item.data,
            color: shuffledColors[index % shuffledColors.length], // Use modulo to cycle through colors
            task: item.task
        }));
        setdatagraph(dataWithColors)
        setLineVisibility(Array(dataWithColors.length).fill(true));

    }, [data])


    // useEffect(() => {
    //     if (!data) return;
    //     const svg = select(svgRef.current);
    //     svg.selectAll(".legend-observed").remove();
    //     svg.selectAll(".legend-smoothed").remove();
    //     svg.append("circle").attr("cx", 10).attr("cy", -15).attr("r", 6).classed("legend", true).classed("legend-observed", true).classed("legend-observed-icon", true).style("fill", "#cccccc").style("cursor", "pointer")
    //     svg.append("text").attr("x", 20).attr("y", -10).classed("legend", true).classed("legend-observed", true).classed("legend-observed-text", true).text("Observed " + String(vegetationIndex)).style("font-size", "15px").style("cursor", "pointer").style("fill", "#bdbdbd")
    //     svg.append("circle").attr("cx", 240).attr("cy", -15).attr("r", 6).classed("legend", true).classed("legend-smoothed", true).classed("legend-smoothed-icon", true).style("fill", "#ffc107").style("cursor", "pointer")
    //     svg.append("text").attr("x", 250).attr("y", -10).classed("legend", true).classed("legend-smoothed", true).classed("legend-smoothed-text", true).text("Smoothed " + String(vegetationIndex)).style("font-size", "15px").style("cursor", "pointer")
    //     setdatagraph(data.data)
    //     setmin(parseFloat(data.min))
    //     setmax(parseFloat(data.max))
    // }, [data]);


    // useEffect(() => {
    //     if (!data_graph_smoothed) return;
    //     setdataSmooth(data_graph_smoothed);
    // }, [data_graph_smoothed]);

    // const handleClickObservedLegend = () => {
    //     const svg = select(svgRef.current);

    //     // Use the state updater function to ensure you work with the latest state
    //     setobservedDataLineState((prevState) => {
    //         if (!prevState) {
    //             svg.selectAll(".line").style("visibility", "visible");
    //             svg.selectAll(".myDot").style("visibility", "visible");
    //             svg.selectAll(".legend-observed-icon").style("fill", "#6f9d2f");
    //             svg.selectAll(".legend-observed-text").style("fill", "#000000");
    //         } else {
    //             svg.selectAll(".line").style("visibility", "hidden");
    //             svg.selectAll(".myDot").style("visibility", "hidden");
    //             svg.selectAll(".legend-observed-icon").style("fill", "#cccccc");
    //             svg.selectAll(".legend-observed-text").style("fill", "#cccccc");
    //         }
    //         // Return the new state value
    //         return !prevState;
    //     });
    // }

    // const handleClickSmoothedLegend = () => {
    //     const svg = select(svgRef.current);

    //     // Use the state updater function to ensure you work with the latest state
    //     setsmoothedDataLineState((prevState) => {
    //         if (!prevState) {
    //             svg.selectAll(".lineSmooth").style("visibility", "visible");
    //             svg.selectAll(".legend-smoothed-icon").style("fill", "#ffc107");
    //             svg.selectAll(".legend-smoothed-text").style("fill", "#000000");
    //         } else {
    //             svg.selectAll(".lineSmooth").style("visibility", "hidden");
    //             svg.selectAll(".legend-smoothed-icon").style("fill", "#cccccc");
    //             svg.selectAll(".legend-smoothed-text").style("fill", "#cccccc");
    //         }
    //         // Return the new state value
    //         return !prevState;
    //     });
    // }


    // useEffect(() => {
    //     const svg = select(svgRef.current);

    //     const observedLegend = svg.selectAll(".legend-observed");

    //     observedLegend.on('click', handleClickObservedLegend);

    //     return () => {
    //         observedLegend.on('click', null);  // Cleanup the event handler when component unmounts
    //     };
    // }, [handleClickObservedLegend]);

    // useEffect(() => {
    //     const svg = select(svgRef.current);

    //     const observedLegend = svg.selectAll(".legend-smoothed");

    //     observedLegend.on('click', handleClickSmoothedLegend);

    //     return () => {
    //         observedLegend.on('click', null);  // Cleanup the event handler when component unmounts
    //     };
    // }, [handleClickSmoothedLegend]);

    const showHideLineChart = (ind) => {
        const newVisibility = [...lineVisibility];
        newVisibility[ind] = !newVisibility[ind];
        setLineVisibility(newVisibility);
    }
    useEffect(() => {
        if (!datagraph) return
        if (!min) return
        const svg = select(svgRef.current);
        const svgContent = svg.select(".content");
        const { width, height } =
            dimensions || wrapperRef.current.getBoundingClientRect();
        if (!dimensions) return;

        const xScale = scaleTime()
            .domain(extent(datagraph[0].data, (d) => new Date(d.date)))
            .range([0, width]);

        if (currentZoom) {
            const newXScale = currentZoom.rescaleX(xScale);
            xScale.domain(newXScale.domain());
        }
        const yScale = scaleLinear()
            .domain([parseFloat(min) - 0.06, parseFloat(max) + 0.02])
            .range([height, 0]);

        const myLine = line()
            .x((d) => xScale(new Date(d.date)))
            .y((d) => yScale(d.value))
            .curve(curveLinear);

        const xAxis = axisBottom(xScale);
        svg
            .select(".x-axis")
            .attr("transform", `translate(0, ${height})`)
            .call(xAxis);
        svg
            .select(".x-axis")
            .selectAll("text")
            .attr("transform", "translate(-5,5)rotate(-45)")
            .style("text-anchor", "end");

        const yAxis = axisLeft(yScale);
        const yAxisGrid = axisLeft(yScale).tickSize(-width).tickFormat("");
        svg.select(".y-axis").call(yAxis);
        // Remove all existing lines
        svgContent.selectAll(".line").remove();
        datagraph.map((item, index) => {
            svgContent
                .selectAll(".line" + index)
                .data([item.data])
                .join("path")
                .attr("class", "line line" + index)
                .attr("fill", "none")
                .attr("stroke", String(item.color))
                .attr("stroke-width", "2px")
                .style("visibility", lineVisibility[index] ? "visible" : "hidden")

                .attr("d", myLine);
        })
        // svgContent
        //     .selectAll(".line")
        //     .data([datagraph])
        //     .join("path")
        //     .attr("class", "line")
        //     .attr("fill", "none")
        //     .attr("stroke", "#6f9d2f")
        //     .attr("stroke-width", "1px")
        //     .style("visibility", observedDataLineState ? "visible" : "hidden")
        //     .attr("d", myLine);
        // svgContent
        //     .selectAll(".lineSmooth")
        //     .data([datagraphSmooth])
        //     .join("path")
        //     .attr("class", "lineSmooth")
        //     .attr("fill", "none")
        //     .attr("stroke", "#ffc107")
        //     .attr("stroke-width", "1px")
        //     .style("visibility", smoothedDataLineState ? "visible" : "hidden")
        //     .attr("d", myLineSmooth);

        // svgContent
        //     .selectAll(".myDot")
        //     .data(datagraph)
        //     .join("circle")
        //     .attr("class", "myDot")
        //     .attr("stroke", "#6f9d2f")
        //     .attr("stroke-width", "1px")
        //     .attr("fill", "#6f9d2f")
        //     .style("visibility", observedDataLineState ? "visible" : "hidden")
        //     .attr("r", "3")
        //     .attr("cx", (d) => xScale(new Date(d.date)))
        //     .attr("cy", (d) => yScale(d.value))
        //     .on("mouseover", function (event, d) {
        //         select(this).transition().duration("100").attr("r", 5);
        //         //Makes div appear
        //         div
        //             .transition()
        //             .duration(100)
        //             .style("opacity", 1)
        //             .style("display", "block");
        //         div
        //             .html(d.cloud_percentage && d.cloud_percentage == -1 ? "Date : " + d.date + "<br>" + t("Value") + ": " + d.value + "<br>" + t("Cloud") + " : " + t("Undefined") : "Date : " + d.date + "<br>" + t("Value") + ": " + d.value + "<br>" + t("Cloud") + " : " + d.cloud_percentage + "%")
        //             .style("left", event.pageX + 10 + "px")
        //             .style("top", event.pageY - 30 + "px");
        //     })
        //     .on("mouseout", function (d, i) {
        //         select(this).transition().duration("200").attr("r", 3);
        //         //makes div disappear
        //         div
        //             .transition()
        //             .duration("200")
        //             .style("opacity", 0)
        //             .style("display", "none");
        //     });
        const zoomBehavior = zoom()
            .translateExtent([
                [0, 0],
                [width, height],
            ])
            .scaleExtent([0.3, 100])
            .on("zoom", (event) => {
                const zoomState = event.transform;
                setcurrentZoom(zoomState);
            });
        svg.call(zoomBehavior);
        // const observedLegend = svg.selectAll(".legend-observed");

        // if (observedLegend.empty()) {
        //     svg.selectAll(".legend-observed").remove();
        //     svg.selectAll(".legend-smoothed").remove();
        //     svg.append("circle").attr("cx", 10).attr("cy", -15).attr("r", 6).classed("legend", true).classed("legend-observed", true).classed("legend-observed-icon", true).style("fill", "#cccccc").style("cursor", "pointer")
        //     svg.append("text").attr("x", 20).attr("y", -10).classed("legend", true).classed("legend-observed", true).classed("legend-observed-text", true).text("Observed " + String(vegetationIndex)).style("font-size", "15px").style("cursor", "pointer").style("fill", "#bdbdbd")
        //     svg.append("circle").attr("cx", 240).attr("cy", -15).attr("r", 6).classed("legend", true).classed("legend-smoothed", true).classed("legend-smoothed-icon", true).style("fill", "#ffc107").style("cursor", "pointer")
        //     svg.append("text").attr("x", 250).attr("y", -10).classed("legend", true).classed("legend-smoothed", true).classed("legend-smoothed-text", true).text("Smoothed " + String(vegetationIndex)).style("font-size", "15px").style("cursor", "pointer")
        // }
    }, [svgRef, datagraph, dimensions, currentZoom, min, max, lineVisibility]);

    return (
        <>
            <Grid item xs={12} container spacing={1} className="mt-3 mb-1">
                {datagraph && datagraph.map((item, index) => {
                    const lineStyle = lineVisibility[index] ? {
                        color: item.color,
                        fontSize: "30px",
                    } : {
                        color: "#c0c0c0",
                        fontSize: "30px",
                    }
                    const taskStyle = lineVisibility[index] ? {
                        color: "#000000",
                    } : {
                        color: "#c0c0c0",
                    }
                    return (
                        <Grid item key={item.color} className="d-flex align-items-center task-legend" onClick={() => showHideLineChart(index)}>
                            <span className="graph-legend-icon-wrapper d-flex justify-content-center align-items-center flex-direction-row mr-2">
                                <SlGraph style={lineStyle} />
                            </span>
                            <span style={taskStyle}>{item.task.task_name}</span>
                        </Grid>
                    )
                })}
            </Grid>
            <Grid item xs={12} ref={wrapperRef} className="svg-container">
                {datagraph ? (
                    <svg ref={svgRef} className="graphIndex">
                        <defs>
                            <clipPath id="MygraphPanel">
                                <rect x="0" y="0" width="100%" height="100%" />
                            </clipPath>
                        </defs>
                        <g className="content" clipPath="url(#MygraphPanel)"></g>
                        <g className="x-axis" />
                        <g className="x-axis-grid" />
                        <g className="y-axis" />
                        {/* <g className="y-axis-grid" /> */}
                    </svg>
                ) : (
                    ""
                )}
            </Grid>
        </>
    );
};

export default Graph;
