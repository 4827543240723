import {
  LAYERS_OVERLAY_SUCESS,
  LAYERS_OVERLAY_FAIL,
  AREA_OF_INTEREST_SUCESS,
  AREA_OF_INTEREST_FAIL,
  AREA_SURFACE_SUCESS,
  AREA_SURFACE_FAIL,
  NDVI_SUCESS,
  NDVI_FAIL,
  LOADING_BEGIN,
  LOADING_END,
  DETAIL_TASK_SUCESS,
  DETAIL_TASK_FAIL,
  SELECTED_DATE_SUCCESS,
  SELECTED_DATE_FAIL,
  SELECTED_DATE_INITIAL_SUCCESS,
  SELECTED_DATE_INITIAL_FAIL,
  ACCUSATION_DATES_SUCCESS,
  ACCUSATION_DATES_FAIL,
  CLICK_EVENT_STATE_SUCCESS,
  CLICK_EVENT_STATE_FAIL,
  SET_DETAILS_TASK_FORM_SUCCESS,
  SET_DETAILS_TASK_FORM_FAIL,
  CHECKBOX_STATE_SUCCESS,
  CHECKBOX_STATE_FAIL,
  SET_TREE_COUNT_ARRAY_SUCCESS,
  SET_TREE_COUNT_ARRAY_FAIL,
  SET_TREE_COUNT_VALUE_SUCCESS,
  SET_TREE_COUNT_VALUE_FAIL,
  SET_IS_CHECKED_COUNT_CHECKBOX_SUCCESS,
  SET_IS_CHECKED_COUNT_CHECKBOX_FAIL,
  TASK_IMAGE_SIDEBAR_SUCCESS,
  TASK_IMAGE_SIDEBAR_FAIL,
} from "../utils/types";

const initialState = {
  LayersOverlayState: null,
  AreaOfIntrest: null,
  AreaSurface: 0,
  LoadingState: 0,
  VegetationLayers: null,
  DetailVegetationLayers: null,
  SelectedDate: null,
  AccusationDates: null,
  clickEventCheck: true,
  taskIdDetails: null,
  taskNameDetails: null,
  surfaceDetails: null,
  ageDetails: null,
  cultureDetails: null,
  textureDetails: null,
  densityDetails: null,
  varietyDetails: null,
  irrigationTypeDetails: null,
  salinityDetails: null,
  efficiencyDetails: null,
  checkboxLayers: "NDVI",
  countryFrDetails: null,
  countryArDetails: null,
  countyFrDetails: null,
  countyArDetails: null,
  postcodeFrDetails: null,
  postcodeArDetails: null,
  stateFrDetails: null,
  stateArDetails: null,
  stateDistrictFrDetails: null,
  stateDistrictArDetails: null,
  imageSidebarArDetails: null,
  treeCountValue: null,
  treeCountLocalIndicesArray: [],
  isCheckedCountCheckbox: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CHECKBOX_STATE_SUCCESS:
      return {
        ...state,
        checkboxLayers: payload,
      };
    case CHECKBOX_STATE_FAIL:
      return {
        ...state,
        checkboxLayers: null,
      };
    case LOADING_BEGIN:
      return {
        ...state,
        LoadingState: payload,
      };
    case LOADING_END:
      return {
        ...state,
        LoadingState: payload,
      };
    case LAYERS_OVERLAY_SUCESS:
      return {
        ...state,
        LayersOverlayState: payload,
        LoadingState: false,
      };
    case LAYERS_OVERLAY_FAIL:
      return {
        ...state,
        LayersOverlayState: null,
      };
    case AREA_OF_INTEREST_SUCESS:
      return {
        ...state,
        AreaOfIntrest: payload,
      };
    case AREA_OF_INTEREST_FAIL:
      return {
        ...state,
        AreaOfIntrest: null,
      };
    case AREA_SURFACE_SUCESS:
      return {
        ...state,
        AreaSurface: payload,
      };
    case AREA_SURFACE_FAIL:
      return {
        ...state,
        AreaSurface: null,
      };
    case NDVI_SUCESS:
      return {
        ...state,
        VegetationLayers: payload,
      };
    case DETAIL_TASK_SUCESS:
      return {
        ...state,
        DetailVegetationLayers: payload,
      };
    case DETAIL_TASK_FAIL:
      return {
        ...state,
        DetailVegetationLayers: null,
      };
    case NDVI_FAIL:
      return {
        ...state,
        VegetationLayers: null,
      };
    case SELECTED_DATE_SUCCESS:
      return {
        ...state,
        SelectedDate: payload,
      };
    case SELECTED_DATE_FAIL:
      return {
        ...state,
        SelectedDate: null,
      };
    case SELECTED_DATE_INITIAL_SUCCESS:
      return {
        ...state,
        SelectedDate: payload,
      };
    case SELECTED_DATE_INITIAL_FAIL:
      return {
        ...state,
        SelectedDate: null,
      };
    case ACCUSATION_DATES_SUCCESS:
      return {
        ...state,
        AccusationDates: payload,
      };
    case ACCUSATION_DATES_FAIL:
      return {
        ...state,
        AccusationDates: null,
      };
    case CLICK_EVENT_STATE_SUCCESS:
      return {
        ...state,
        clickEventCheck: true,
      };
    case CLICK_EVENT_STATE_FAIL:
      return {
        ...state,
        clickEventCheck: false,
      };
    case SET_DETAILS_TASK_FORM_SUCCESS:
      return {
        ...state,
        taskIdDetails: payload.id,
        taskNameDetails: payload.task_name,
        surfaceDetails: payload.surface,
        ageDetails: payload.age,
        cultureDetails: payload.culture,
        textureDetails: payload.texture,
        densityDetails: payload.density,
        varietyDetails: payload.variety,
        irrigationTypeDetails: payload.irrigation,
        salinityDetails: payload.salinity,
        efficiencyDetails: payload.efficiency,

        countryFrDetails: payload.country_fr,
        countryArDetails: payload.country_ar,
        countyFrDetails: payload.county_fr,
        countyArDetails: payload.county_ar,
        postcodeFrDetails: payload.postcode_fr,
        postcodeArDetails: payload.postcode_ar,
        stateFrDetails: payload.state_fr,
        stateArDetails: payload.state_ar,
        stateDistrictFrDetails: payload.state_district_fr,
        stateDistrictArDetails: payload.state_district_ar,
        imageSidebarArDetails: payload.image_sidebar,
      };
    case SET_DETAILS_TASK_FORM_FAIL:
      return {
        ...state,
        taskIdDetails: null,
        taskNameDetails: null,
        surfaceDetails: null,
        ageDetails: null,
        cultureDetails: null,
        textureDetails: null,
        densityDetails: null,
        varietyDetails: null,
        irrigationTypeDetails: null,
        salinityDetails: null,
        efficiencyDetails: null,

        countryFrDetails: null,
        countryArDetails: null,
        countyFrDetails: null,
        countyArDetails: null,
        postcodeFrDetails: null,
        postcodeArDetails: null,
        stateFrDetails: null,
        stateArDetails: null,
        stateDistrictFrDetails: null,
        stateDistrictArDetails: null,
        imageSidebarArDetails: null,
      };
    case SET_TREE_COUNT_VALUE_SUCCESS:
      return {
        ...state,
        treeCountValue: payload,
      };
    case SET_TREE_COUNT_VALUE_FAIL:
      return {
        ...state,
        treeCountValue: null,
      };
    case SET_TREE_COUNT_ARRAY_SUCCESS:
      return {
        ...state,
        treeCountLocalIndicesArray: payload,
      };
    case SET_TREE_COUNT_ARRAY_FAIL:
      return {
        ...state,
        treeCountLocalIndicesArray: null,
      };
    case SET_IS_CHECKED_COUNT_CHECKBOX_SUCCESS:
      return {
        ...state,
        isCheckedCountCheckbox: 1,
      };
    case SET_IS_CHECKED_COUNT_CHECKBOX_FAIL:
      return {
        ...state,
        isCheckedCountCheckbox: 0,
      };
    case TASK_IMAGE_SIDEBAR_SUCCESS:
      return {
        ...state,
        imageSidebarArDetails: payload,
      };
    case TASK_IMAGE_SIDEBAR_FAIL:
      return {
        ...state,
        imageSidebarArDetails: null,
      };
    default:
      return {
        ...state,
      };
  }
}
