import axios from "axios";

import {
  HISTOGRAM_SUCCESS,
  HISTOGRAM_FAIL,
  SET_HISTOGRAM_INTERVAL,
} from "../utils/types.js";

export const getHistogram =
  (id, date, provider = "S2", index = "NDVI") =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ id, date, provider, index });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/task/get/index/`,
        body,
        config
      );
      console.log("res index", res);
      dispatch({
        type: HISTOGRAM_SUCCESS,
        payload: res.data.data,
      });
      return res;
    } catch (err) {
      console.log('trigger histogram fail');
      dispatch({
        type: HISTOGRAM_FAIL,
      });
      return err;
    }
  };
export const getHistogramStatic =
  (index = "NDVI", density) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ index, density });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/task/histogram/static/get/`,
        body,
        config
      );
      const interval = [
        parseFloat(res.data.data.min),
        parseFloat(res.data.data.max),
      ];
      console.log("interval", interval);
      console.log("res range", res);
      dispatch({
        type: SET_HISTOGRAM_INTERVAL,
        payload: interval,
      });
      return res;
    } catch (err) {
      return err;
    }
  };
export const setHistogramStatic =
  (task, index = "NDVI", min, max) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ task, index, min, max });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/task/histogram/static/set/`,
        body,
        config
      );
      return res;
    } catch (err) {
      return err;
    }
  };
