import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/Inbox";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import { get_user_notifications } from "../../actions/notification.js";
import loadingImg from "../../assets/images/loading_small.svg";
import TaskImage from "../../components/task/sidebars/TaskImage.js";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Badge from "@mui/material/Badge";
import { useNavigate } from "react-router-dom";
import { get_user_notifications_head, getUserSensorHumidityNotifications, alterSensorHumidityNotificationState } from "../../actions/notification.js";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import WaterDropIcon from '@mui/icons-material/Opacity';

const Notification = () => {
  const { t } = useTranslation();
  const [loading, setloading] = useState(true);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const notifications = useSelector(
    (state) => state.notificationReducer.notifications
  );
  const navigate = useNavigate();
  const [notificationState, setnotificationState] = useState(null);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    // padding: theme.spacing(1),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const [Username, setUsername] = useState(null);
  const [notificationCount, setnotificationCount] = useState(0);

  const [humidityAlertNotifications, setHumidityAlertNotifications] = useState(null);
  const [humidityAlertNotificationsCount, setHumidityAlertNotificationsCount] = useState(0);


  useEffect(() => {
    if (!notifications) return;
    setnotificationState(notifications);
  }, [notifications]);
  useEffect(() => {
    if (!user) return;
    dispatch(get_user_notifications(user.id)).then((res) => {
      setloading(false);
    });
  }, [user]);

  const redirectToTask = (task_id, alert_id) => {
    // alert(id)
    dispatch(alterSensorHumidityNotificationState([alert_id]))
    navigate(`/task/detail/${task_id}`);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 to the month and pad with leading zero
    const day = String(date.getDate()).padStart(2, "0"); // Pad with leading zero

    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (!user) return;
    const username = user.firstname + " " + user.lastname;
    setUsername(username);
    dispatch(getUserSensorHumidityNotifications(user.id, 0)).then((res) => {
      if (res.status == 200) {
        setHumidityAlertNotifications(res.data.alerts)
        setHumidityAlertNotificationsCount(res.data.new_count)
      }
    });
  }, [user]);

  useEffect(() => {
    if (!humidityAlertNotifications) return;
    setHumidityAlertNotificationsCount(humidityAlertNotifications.length);
    setnotificationCount(111)
    // prevCount => prevCount + humidityAlertNotificationsCount);
  }, [humidityAlertNotifications]);


  const formatTime = (dateString) => {
    const time = new Date(dateString);
    // const year = date.getFullYear();
    // const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 to the month and pad with leading zero
    // const day = String(date.getDate()).padStart(2, "0"); // Pad with leading zero

    // return `${year}-${month}-${day}`;
    const formattedTime = time.toLocaleString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'UTC'
    }).replace(', ', ' at ')

    return formattedTime;
  };

  return (
    <Grid container spacing={2} sx={{ p: 2, flex: 1 }}>
      <Grid item xs={12}>
        <div className="intro-text">
          <h2>{t("Notifications")}</h2>
          {/* <p>
                                This page allows you to view and manage your projects and associated tasks. Below is a table displaying the projects and their respective information.
                                You can expand the rows to view the tasks associated with each project. Use the pagination controls to navigate through the projects.
                            </p> */}
        </div>
      </Grid>
      <Grid item xs={12}>
        {loading === false ? (
          <Grid item xs={12}>
            <Item>
              <List style={{ borderBottom: '1px #909090 solid' }}>
                {humidityAlertNotifications && humidityAlertNotificationsCount !=0 ? (
                  humidityAlertNotifications.map((item, index) => (

                    <React.Fragment key={index}>
                      {item.alerts.map((alert, alertIndex) => (

                        <ListItem
                          disablePadding
                          key={alertIndex}
                          onClick={() => redirectToTask(item.task_id, alert.id)}
                          style={{ paddingBottom: "8px" }}
                        >
                          <ListItemButton>
                            <ListItemIcon>
                              <WaterDropIcon
                                color={alert.viewed ? "grey" : "primary"}
                                style={{ opacity: alert.viewed ? ".5" : "1" }}
                              />
                            </ListItemIcon>
                            <ListItemText style={{ opacity: alert.viewed ? ".5" : "1" }}
                              primary={
                                (
                                  <>
                                    <span style={{color: "#909090", padding: "2px", borderRadius: "4px" }}>{alert.description}</span>{" "}detected for your plot {" "}
                                    <span style={{color: "#909090", padding: "2px", borderRadius: "4px" }}>{item.task_name}</span> on{" "}
                                    <span style={{color: "#909090", padding: "2px", borderRadius: "4px" }}>
                                      {formatTime(alert.time)}
                                    </span>{" "}
                                    humidity level:  <span style={{ border: "1px #909090 solid", color: "#909090", padding: "0.3rem", borderRadius: "4px" }}>{parseFloat(alert.measured_value)}</span>
                                  </>
                                )
                              }
                            >
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      )
                      )
                      }

                    </React.Fragment>
                  )
                  )
                ) : (
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center", p: 5 }}
                  >
                    <Typography variant="body2" gutterBottom>
                    No soil humidity levels notifications at the moment.
                    </Typography>
                  </Grid>
                )
                }

              </List>
            </Item>
            <Item>
              <List>
                {notificationState && notificationState.length > 0 ? (
                  notificationState.map((item, index) => (
                    <React.Fragment key={index}>
                      {/* <Grid container sx={{ m: 1 }}>

                                                {<TaskImage taskId={item.id} />}
                                                <Grid item>#{item.id}</Grid>
                                                <Grid item>{item.task_name}</Grid>
                                                <Grid></Grid>
                                            </Grid> */}
                      {/* <Grid item xs={4}>
                                        <Card sx={{ backgroundColor: "#ffffff!important",padding:2 }} >
                                            <CardMedia
                                                sx={{
                                                    display: "flex", marginLeft: "auto",
                                                    marginRight: "auto", maxWidth: "100%", height: 150, backgroundSize: "contain"
                                                }}
                                                image={`${process.env.REACT_APP_API_URL}${item.image_sidebar}`}
                                                title="green iguana"
                                            />


                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    {item.task_name}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Lizards are a widespread group of squamate reptiles, with over 6,000
                                                    species, ranging across all continents except Antarctica
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid> */}

                      {item.alerts.map((itemAlert, alertIndex) => (
                        <React.Fragment key={index}>
                          {/* <Badge color="IconColor" badgeContent=" " overlap="circular" style={{ transform: 'translate(30px, -20px)!important ', width: '100%' }} onClick={() => redirectToTask(item.id)}
                                                    > */}

                          <ListItem
                            disablePadding
                            key={alertIndex}
                            onClick={() => redirectToTask(item.id)}
                          >
                            <ListItemButton>
                              {/* {itemAlert.id}
                                                            {itemAlert.date}
                                                            {itemAlert.abs_difference} */}
                              <ListItemIcon>
                                <ReportProblemIcon
                                  color={
                                    parseFloat(itemAlert.abs_difference) > 0.2
                                      ? "DeleteTask"
                                      : "DownloadReport"
                                  }
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  parseFloat(itemAlert.abs_difference) > 0.2 ? (
                                    <>
                                      Task <strong>{item.task_name}</strong>{" "}
                                      experienced a notable difference in the
                                      vegetation index on{" "}
                                      <strong>
                                        {formatDate(itemAlert.date)}
                                      </strong>
                                      . Please investigate.
                                    </>
                                  ) : (
                                    <>
                                      An alert has been raised for task{" "}
                                      <strong>{item.task_name}</strong> on{" "}
                                      <strong>
                                        {formatDate(itemAlert.date)}
                                      </strong>{" "}
                                      because the vegetation index diverged from
                                      the predicted value.
                                    </>
                                  )
                                }
                              ></ListItemText>
                            </ListItemButton>
                          </ListItem>
                          {/* </Badge> */}
                          <Divider />
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))
                ) : (
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center", p: 5 }}
                  >
                    <Typography variant="body2" gutterBottom>
                    No vegetation related notifications at the moment.
                    </Typography>
                  </Grid>
                )}
              </List>
            </Item>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "10vh",
              }}
            >
              <img src={loadingImg} alt="Loading" />
            </Item>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Notification;
